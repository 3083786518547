<template>
  <div id="app">
    <NavMenu :Login="Login" />
    <div :class="Login ? 'view' : 'views'">
      <div
        :style="{
          width: !isCollapse ? ' calc(100% - 234px)' : ' calc(100% - 64px)',
        }"
        class="div"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import NavMenu from "@/components/NavMenu.vue"; //侧边导航栏
export default {
  data() {
    return {
      isCollapse: false,
      Login: true,
    };
  },
  components: {
    NavMenu,
  },
  watch: {
    // 页面
    $route(to, from) {
      let Path = to.path;
      if (Path == "/Login") {
        this.Login = false;
      } else {
        this.Login = true;
      }
    },
  },
  methods: {},
  created() {
  },
};
</script>
<style lang="less">
// 挂载全局的css
@import url("./assets/css/qcmr.css");
@import url("./assets/css/iconfont.css");
//
.view {
  width: 100%;
  display: flex;
  height: calc(100vh - 60px);
  justify-content: flex-end;
  // padding: 20px;
  box-sizing: border-box;
  background: #f8f8f8;
}
.views {
  width: 100%;
  height: 100vh;
}
html body::-webkit-scrollbar {
    display: none;
}
.operation {
  display: flex;
  justify-content: center;
  width: 500px;
  div {
    width: 88px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    letter-spacing: 2px;
    margin-left: 30px;
    cursor: pointer;
  }
  .cz {
    border: 1px solid #ddd;
    color: #444;
    background: #f4f4f4;
  }
  .xyb {
    background: #328ffe;
    color: #ffffff;
  }
  .syb{
    color: #ffffff;
    background: #F75444;
  }
}
// deep
// /deep/ .el-menu-item i {
//   color: #ffffff !important;
// }
</style>

<template>
  <div class="Lndividual">
    <div class="Lndividual_from">
      <div class="Lndividual_from_box">
        <div class="Lndividual_from_box_left">
          <!-- 信息 -->
          <div class="MedicalInfo Medical">
            <div class="name">姓名</div>
            <div class="txt">{{ MedicalInfo.name }}</div>
            <div class="name">年龄</div>
            <div class="txt">{{ MedicalInfo.age }}</div>
            <div class="name">性别</div>
            <div class="txt">{{ MedicalInfo.gender_text }}</div>
            <div class="name">出生日期</div>
            <div class="txt">{{ MedicalInfo.birth }}</div>
          </div>
          <div class="Lndividual_from_scroll">
            <!-- 备注 -->
            <div class="MedicalRemarks Medical beizhu">
              <div class="name">备注</div>
              <div class="input">{{ MedicalInfo.summary }}</div>
            </div>
            <!-- 四诊信息 -->
            <div class="Diagnosis Medical sizhen">
              <div class="name">四诊信息</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItem"
                  v-for="(item, index) in DiagnosisArr"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <!-- 病机 -->
            <div class="Diagnosis Medicals bingji">
              <div class="name div">病机</div>
              <!-- <div class="DiagnosisInfo">
								<div class="DiagnosisItems" v-for="(item, index) in PathogenesisArr" :key="index">
									<div class="DiagnosisItems_title">方证{{ index + 1 }}：{{ item.name }}</div>
								</div>
							</div> -->
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItems"
                  v-for="(item, index) in PathogenesisArr"
                  :key="index"
                >
                  <div class="DiagnosisItems_title">
                    {{ item.name }} ( {{ item.percent }})
                  </div>
                  <div class="DiagnosisItems_info">
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">六经:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.liujing }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">脏腑经络:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zangfu }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">八纲六邪:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.bagang }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">治则:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zhize }};
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Diagnosis Medicals zufang">
              <div class="name div">组方</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItems"
                  v-for="(item, index) in GroupArr"
                  :key="index"
                >
                  <div class="DiagnosisItems_title">
                    组方{{ index + 1 }}：{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="Diagnosis Medicals fangji">
              <div class="name div">方剂</div>
              <div class="DiagnosisInfo">
                <div
                  v-show="MedicalInfo.step == '处方生成'"
                  class="DiagnosisItems"
                >
                  <div class="DiagnosisItems_zufang">
                    <div v-for="(item, index) in YaoJiArr" :key="index">
                      {{ item.name }}{{ item.num }}{{ item.unit }}
                      <span v-if="index != YaoJiArr.length - 1">,</span>
                    </div>
                  </div>
                  <div class="DiagnosisItems_zufang_title">煎服法:</div>
                  <div class="DiagnosisItems_zufang_list">
                    <div
                      class=""
                      v-for="(item, index) in JianFuArr"
                      :key="index"
                    >
                      <div>{{ index + 1 }}、 {{ item.value }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 医师 -->
          <div class="Physician Medical">
            <div class="PhysicianInfo">
              <div class="name">医师:</div>
              <div class="txt">{{ userInfo.nickname }}</div>
            </div>
          </div>
        </div>
        <!-- 操作 -->
        <div class="operation operation_row operation_top">
          <div class="syb" @click="GetPrevious">上一步</div>
          <div class="cz" @click="Reset">重置</div>
          <div class="xyb" @click="editChufang('next')">下一步</div>
        </div>
      </div>
      <!-- 组方列表 -->
      <div class="tuijian_list">
        <div class="operation operation_row">
          <div class="xyb" @click="editChufang">合方化裁</div>
        </div>
        <div v-show="!isEdit" class="Search_listss">
          <div class="Search_item_scroll">
            <div>
              <div
                class="Search_item"
                v-for="(item, index) in PrescriptionArr"
                :key="index"
              >
                <div class="zufang_index">组方{{ index + 1 }}</div>
                <div class="zufang_title">{{ item.name }}</div>
                <div class="zufang_info">
                  <div class="zufang_yaoji">
                    <div
                      v-for="(items, indexs) in item.fangji_json"
                      :key="indexs"
                    >
                      <div style="color: #000; margin-left: 4px">
                        {{ items.name }}{{ items.num }}{{ items.unit }}
                      </div>
                      <!-- <el-checkbox v-model="items.choice" @change="ChoiceDiagnosis(items, index, 1)"></el-checkbox> -->
                    </div>
                  </div>
                  <div class="zufang_fangfa_title">煎服法:</div>
                  <div class="zufang_fangfa">
                    <div
                      v-for="(items, indexs) in item.jianfu_json"
                      :key="indexs"
                    >
                      <div class="zufang_fangfa_val" style="color: #000">
                        {{ items.value }}
                      </div>
                      <!-- <el-checkbox v-model="items.choice" @change="ChoiceDiagnosis(items, index, 2)"></el-checkbox> -->
                    </div>
                  </div>
                </div>
                <div class="zufang_msg">
                  <p v-show="item.comment != ''">提示信息</p>
                  {{ item.comment || "无" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="isEdit" class="chufang">
          <div class="Diagnosis Medicals Diagnosis_ChuFang">
            <div class="name w div">处方</div>
            <div class="DiagnosisInfo">
              <div class="ChuFang_YaoJi">
                <div class="ChuFang_YaoJi_list">
                  <div
                    class="ChuFang_YaoJi_item"
                    v-for="(item, index) in chufangList"
                    :key="index"
                  >
                    <div class="ChuFang_YaoJi_item_name">{{ item.name }}</div>
                    <div class="ChuFang_YaoJi_item_num">
                      <el-input-number
                        size="mini"
                        v-model="item.nums"
                        @change="handleChange"
                        :min="1"
                      ></el-input-number>
                    </div>
                    <div class="ChuFang_YaoJi_item_dropdown">
                      <el-dropdown size="mini" @command="handleCommand">
                        <div class="xianshi">
                          {{ item.unit }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              v-for="(items, indexs) in UnitList"
                              :key="indexs"
                              :command="items"
                              @click="handleIndex(index, items)"
                            >
                              {{ items.name }}
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </div>
                    <div
                      class="DeleteChuFang"
                      @click="DeleteChuFang(item, index)"
                    >
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ChuFang_YaoJi_add">
                <div class="ChuFang_YaoJi_add_btn" @click="AddYaoJi">
                  自主添加
                </div>
                <div class="ChuFang_YaoJi_add_dose">
                  共<el-input-number
                    size="mini"
                    v-model="doseNum"
                    @change="handleChange"
                    :min="1"
                  ></el-input-number
                  >剂
                </div>
              </div>
              <!-- <div class="ChuFang_JianFu">
								<div>
									<div class="ChuFang_JianFu_title">煎服法:</div>
									<div class="ChuFang_JianFu_list">
										<div class="ChuFang_JianFu_item" v-for="(item, index) in JianFuArr" :key="index">{{ index + 1 }}、 {{ item.value }}</div>
									</div>
								</div>
							</div> -->
            </div>
            <div class="Diagnosis-right">
              <div
                class="right_text"
                v-for="val in PrescriptionArr"
                :key="val.id"
                v-show="val.comment != ''"
              >
                <div class="title">{{ val.name }}</div>
                <p>{{ val.comment }}</p>
              </div>
            </div>
          </div>
          <!-- 医嘱 -->
          <div class="Diagnosis Medicals Diagnosis_YiZhu">
            <div class="name w div">医嘱</div>
            <div class="DiagnosisInfo">
              <textarea
                v-model="Search"
                placeholder="医生可自主添加相关备注"
                class="YiZhu_input"
                :cols="4"
                wrap="hard"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 自主添加弹框 -->
    <el-dialog title="添加药剂" v-model="YaoJiShow" style="width:30%;">
      <div class="add_yaoji">
        <div class="yao_name">
          <div class="name">药名</div>
          <div class="inputs">
            <el-input
              v-model="YaoJiInfo.name"
              placeholder="请输入药名"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="yao_name">
          <div class="name">剂量</div>
          <div class="inputs">
            <el-input-number
              v-model="YaoJiInfo.nums"
              @change="handleChange"
              :min="1"
            ></el-input-number>
          </div>
        </div>
        <div class="yao_name">
          <div class="name">单位</div>
          <div class="inputs">
            <el-dropdown @command="YaoJiCommand">
              <div class="danwei">
                {{ YaoJiInfo.unit || "请选择单位" }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in UnitList"
                    :key="index"
                    :command="item"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div class="yao_name">
          <div class="xyb" @click="add_push">确定添加</div>
          <div class="cz" @click="add_cz">重置</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      MedicalInfo: [],
      DiagnosisArr: [],
      PathogenesisArr: [], //病机
      GroupList: [],
      GroupArr: [],
      PrescriptionArr: [],
      JianFuArr: [],
      YaoJiArr: [],
      isEdit: false,
      UnitList: [],
      chufangList: [],
      doseNum: 1,
      YaoJiInfo: {
        name: "",
        nums: 1,
        unit: "",
      },
      YaoJiShow: false,
      Search: "",
      PrescriptionMessage: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (!this.$route.params.id) return this.$router.go(-1);
    this.GetMedicalInfo();
    this.GetMedicalType();
    this.GetPathogenesis();
    this.GetPrescription();
    this.GetDropdown();
    this.$nextTick(() => {
      setTimeout(() => {
        let msg = document.getElementsByClassName("Lndividual_from_scroll")[0];
        let scrollHei = document.getElementsByClassName("beizhu")[0];
        let scrollHei2 = document.getElementsByClassName("sizhen")[0];
        let scrollHei3 = document.getElementsByClassName("bingji")[0];
        msg.scrollTop =
          scrollHei.scrollHeight +
          scrollHei2.scrollHeight +
          scrollHei3.scrollHeight;
      }, 200);
    });
    return;
    let GroupArr = this.$route.params.info;
    GroupArr = JSON.parse(GroupArr);
    this.GroupArr = GroupArr; //组方
    let arr = [];
    for (let x = 0; x < GroupArr.length; x++) {
      // 赋值选中状态
      let info = GroupArr[x].info;
      for (let y = 0; y < info.fangji_json.length; y++) {
        info.fangji_json[y].choice = true;
        this.YaoJiArr.push(info.fangji_json[y]);
      }
      for (let y = 0; y < info.jianfu_json.length; y++) {
        info.jianfu_json[y].choice = true;
        this.JianFuArr.push(info.jianfu_json[y]);
      }
      arr.push(info);
    }
    this.PrescriptionArr = arr; // 方剂
  },
  methods: {
    // 选择单位
    YaoJiCommand(command) {
      this.YaoJiInfo.unit = command.name;
    },
    // 自主添加
    // 重置
    add_cz() {
      let YaoJiInfo = {
        name: "",
        nums: "",
        unit: "",
      };
      this.YaoJiInfo = YaoJiInfo;
    },
    // 自组添加
    AddYaoJi() {
      this.add_cz();
      this.YaoJiShow = true;
    },
    // 确定添加
    add_push() {
      if (!this.YaoJiInfo.name) return this.ElmMsg("请填写药剂名称!");
      // 判断是否重名
      for (let x = 0; x < this.chufangList.length; x++) {
        if (this.chufangList[x].name == this.YaoJiInfo.name) {
          return this.ElmMsg("已有当前药剂名称!");
        }
      }
      if (!this.YaoJiInfo.nums) return this.ElmMsg("请填写剂量!");
      if (!this.YaoJiInfo.unit) return this.ElmMsg("请填写药剂单位!");
      let YaoJiInfo = this.YaoJiInfo;
      YaoJiInfo.nums = Number(YaoJiInfo.nums);
      // console.log(YaoJiInfo);
      this.chufangList.push(YaoJiInfo);
      // console.log(this.chufangList);
      this.YaoJiShow = false;
    },
    // 加减划裁
    editChufang(type) {
      if (this.chufangList.length == 0) {
        let list = [];
        this.PrescriptionArr.forEach((item, index) => {
          list.push(...item.fangji_json);
        });
        let resultArray = [],
          itemIds = {};
        list.forEach((item, index) => {
          let str = item.name.indexOf("（") > -1 ? "（" : "(";
          let name = item.name.split(str)[0];
          if (!itemIds[name]) {
            itemIds[name] = true;
            resultArray.push(item);
          } else {
            let repeatIndex = resultArray.findIndex(
              (item2) => item2.name.indexOf(name) > -1
            );
            if (parseInt(resultArray[repeatIndex].num) < parseInt(item.num))
              resultArray[repeatIndex] = item;
          }
        });
        itemIds = {};
        resultArray.forEach((item, index) => {
          item.nums = parseInt(item.num);
        });
        this.chufangList = resultArray;
      }
      if (type == "next") this.GetNext();
      else this.isEdit = !this.isEdit;
    },
    // 上一步
    GetPrevious() {
      this.$router.push({
        name: "Group",
        params: { id: this.$route.params.id, type: 2 },
      });
    },
    // 删除处方
    async DeleteChuFang(item, index) {
      let result = await this.ElmOpen("是否要删除该药剂!", "确定");
      console.log(result);
      if (result == "confirm") {
        // 删除
        let ChuFangList = this.chufangList;
        ChuFangList.splice(index, 1);
        this.ChuFangList = ChuFangList;
        this.ElmMsg("删除成功!");
      }
    },
    // 下一步
    async GetNext() {
      // if(!this.Search)	return this.ElmMsg('请输入医嘱!')
      let ChuFangList = this.chufangList;
      let JianFuArr = this.JianFuArr;
      let jianfu_json = [];
      let fangji_json = [];
      for (let x = 0; x < ChuFangList.length; x++) {
        let name =
          ChuFangList[x].name + ChuFangList[x].nums + ChuFangList[x].unit;
        fangji_json.push(name);
      }
      if (fangji_json.length == 0) return this.ElmMsg("请选择方剂!");
      // fangji_json = fangji_json.join(",");
      fangji_json = JSON.stringify(fangji_json);
      for (let x = 0; x < JianFuArr.length; x++) {
        let name = JianFuArr[x].value;
        jianfu_json.push(name);
      }
      // jianfu_json = jianfu_json.join(",");
      jianfu_json = JSON.stringify(jianfu_json);
      let { data } = await this.$axios.post(
        "/api/yian/set_fangji",
        {
          yian_id: this.$route.params.id,
          comment: this.Search,
          jianfu_json,
          fangji_json,
          num: this.doseNum,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$router.push({
        name: "Export",
        params: { id: this.$route.params.id },
      });
      // this.$router.push({
      // 	name: 'Formula',
      // 	params: {
      // 		id: this.$route.params.id,
      // 		info: JSON.stringify(this.PrescriptionArr)
      // 	}
      // });
    },
    // 重置
    Reset() {
      this.YaoJiArr = [];
      this.JianFuArr = [];
    },
    async GetMedicalInfo() {
      let { data } = await this.$axios.get(
        "/api/yian/get_basic?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.MedicalInfo = data.data;
    },
    async GetMedicalType() {
      let { data } = await this.$axios.get(
        "/api/yian/get_bingzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.code == 1) {
        this.DiagnosisArr = data.data;
      }
    },
    // 查看病机
    async GetPathogenesis() {
      let { data } = await this.$axios(
        "/api/yian/get_bingji?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.PathogenesisArr = data.data.list;
    },
    // 查看方证
    async GetPrescription() {
      let { data } = await this.$axios.get(
        "/api/yian/get_fangzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      //   转json
      let Prescription = data.data;
      for (let x = 0; x < Prescription.length; x++) {
        Prescription[x].fangji_json = JSON.parse(Prescription[x].fangji_json);
        Prescription[x].jianfu_json = JSON.parse(Prescription[x].jianfu_json);
        Prescription[x].jinji_json = JSON.parse(Prescription[x].jinji_json);
        Prescription[x].leifang_json = JSON.parse(Prescription[x].leifang_json);
        for (let y = 0; y < Prescription[x].fangji_json.length; y++) {
          Prescription[x].fangji_json[y].choice = true;
          this.YaoJiArr.push(Prescription[x].fangji_json[y]);
        }
        for (let y = 0; y < Prescription[x].jianfu_json.length; y++) {
          Prescription[x].jianfu_json[y].choice = true;
          this.JianFuArr.push(Prescription[x].jianfu_json[y]);
          let str = "煎服法\n";
          this.JianFuArr.forEach((item, index) => {
            str += `${index + 1}：${item.value}\n`;
          });
          this.Search = str;
        }
      }
      this.GroupArr = Prescription; //组方
      this.PrescriptionArr = Prescription; // 方剂
    },
    async GetDropdown() {
      let { data } = await this.$axios.get("/api/common/info");
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.UnitList = data.data.unit;
    },
    // 取消勾选
    ChoiceDiagnosis(item, index, type) {
      if (item.choice) {
        this.PrescriptionArr[index].choice = true;
      } else {
        this.PrescriptionArr[index].choice = false;
      }
      //   根据type 去删除/添加 对应数组
      if (type == 2) {
        let JianFuArr = this.JianFuArr;
        if (item.choice) {
          if (JianFuArr.length != 0) {
            for (let x = 0; x < JianFuArr.length; x++) {
              if (JianFuArr[x].name != item.name) {
                return JianFuArr.push(item);
              }
            }
          } else {
            JianFuArr.push(item);
          }
        } else {
          // 删除煎服法
          let Num;
          for (let x = 0; x < JianFuArr.length; x++) {
            if (JianFuArr[x].name == item.name) {
              Num = x;
            }
          }
          JianFuArr.splice(Num, 1);
        }
        this.JianFuArr = JianFuArr;
      } else if (type == 1) {
        let YaoJiArr = this.YaoJiArr;
        if (item.choice) {
          if (YaoJiArr.length != 0) {
            for (let x = 0; x < YaoJiArr.length; x++) {
              if (YaoJiArr[x].name != item.name) {
                return YaoJiArr.push(item);
              }
            }
          } else {
            YaoJiArr.push(item);
          }
        } else {
          // 删除药剂
          let Num;
          for (let x = 0; x < YaoJiArr.length; x++) {
            if (YaoJiArr[x].name == item.name) {
              Num = x;
            }
          }
          YaoJiArr.splice(Num, 1);
        }
        this.YaoJiArr = YaoJiArr;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.zt_active {
  color: #fff !important;
  background: #f75444 !important;
}

.zt_active1 {
  color: red !important;
}

.fangji {
  height: auto !important;

  .name {
    min-height: 30px !important;
  }
}

.zufang {
  height: auto !important;

  .name {
    min-height: 30px !important;
  }
}

.Lndividual {
  width: 100%;

  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 0;
    box-sizing: border-box;

    // padding-bottom:0 ;
    // height: calc(100vh - 100px);
    .Lndividual_from_box {
      height: 35%;

      .Lndividual_from_scroll {
        height: calc(100% - 75px);
        overflow-y: auto;

        .DiagnosisInfo {
          padding-bottom: 10px;
        }
      }
    }

    .MedicalInfo {
      height: 38px;
    }

    .Medical {
      display: flex;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }

      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }

      .txt {
        width: 150px;
      }
    }

    .MedicalRemarks {
      height: 66px;

      .input {
        width: calc(100% - 140px);
      }
    }

    .Diagnosis {
      height: 80px;

      .DiagnosisInfo {
        // width: calc(100% - 140px);
        width: calc(72% - 140px);
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        border-top: 1px solid #dcdfe6;
        border-right: 1px solid #dcdfe6;

        &::-webkit-scrollbar {
          display: none;
        }

        .DiagnosisItems {
          width: 100%;
          margin-top: 10px;

          .DiagnosisItems_title {
            width: 100%;
            display: flex;
            font-size: 14px;
            font-weight: 100 !important;
          }

          .DiagnosisItems_zufang {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            // height: 20px;
          }

          .DiagnosisItems_zufang_title {
            font-size: 14px;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 4px;
          }
        }

        .DiagnosisItem {
          padding: 5px 12px;
          box-sizing: border-box;
          //   background: #409eff;
          color: #ffffff;
          height: 30px;
          border-radius: 4px;
          margin-right: 8px;
          position: relative;

          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;
            box-sizing: border-box;
            font-size: 12px;
          }
        }
      }

      .DiagnosisInfo_item {
        width: 15%;
        line-height: 150%;
        padding: 5px;
        overflow-y: auto;
        box-sizing: border-box;
      }
    }

    .Medicals {
      display: flex;

      .div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }

      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }

      .txt {
        width: 150px;
      }

      .w {
        width: 100px !important;
      }
    }

    .Physician {
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #f4f4f4;

      .PhysicianInfo {
        display: flex;
        height: 100%;

        div {
          border-bottom: none !important;
          border-top: none !important;
        }
      }
    }

    .operation {
      // width: 100% !important;
    }

    .operation_row {
      display: block;
      width: 10% !important;

      div {
        margin-top: 0;
        height: 40px;
      }
    }

    .operation_top {
      margin-top: 30px;

      div {
        margin-top: 15px;
      }
    }
  }
}

.tuijian_list {
  width: 100%;
  display: block;
  height: 65%;

  .operation_top {
    margin-top: 0;
  }

  .chufang {
    height: calc(100% - 40px);

    .chufang_item {
      width: 15%;
      // padding: 40px 0 20px 0;
      justify-content: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      p {
        line-height: 16px;
      }
    }

    .DiagnosisInfo {
      padding: 0 10px !important;

      .ChuFang_YaoJi_item_name {
        width: 56px !important;
        margin-right: 5px !important;
      }

      .DeleteChuFang {
        width: 28px !important;
        margin-left: 5px !important;
      }

      .ChuFang_YaoJi_item_dropdown {
        margin-left: 5px !important;
      }

      /deep/ .el-dropdown-selfdefine {
        margin-left: 0 !important;
      }
    }
  }

  .Diagnosis_ChuFang {
    min-height: 130px;
    height: 60% !important;
    background: #fafafa;

    .Diagnosis-right {
      width: 30%;
      padding: 10px 5px;
      height: calc(200% - 42%);
      // height: 100%;
      overflow-y: auto;
      background: #f5f5f5;

      .title {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 5px;
      }

      p {
        line-height: 18px;
        white-space: pre-line;
      }
    }

    .ChuFang_YaoJi {
      // height: 55%;
      // display: flex;
      // justify-content: space-between;
      height: calc(100% - 28px - 40px);
      overflow: auto;

      .ChuFang_YaoJi_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .ChuFang_YaoJi_item {
          display: flex;
          align-items: center;
          margin-top: 30px;
          width: 240px;

          .ChuFang_YaoJi_item_name {
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
            width: 45px;
          }

          .ChuFang_YaoJi_item_num {
            .el-input-number {
              width: 100px !important;
            }
          }

          .ChuFang_YaoJi_item_dropdown {
            height: 28px;

            .xianshi {
              width: 100%;
              height: 100%;
              border: 1px solid #dcdfe6;
              margin-left: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              // background: #409eff;
              // color: #fff;
              color: #333;
              border-radius: 4px;

              i {
                margin-left: 4px;
              }
            }

            /deep/ .el-dropdown {
              height: 100%;
            }
          }

          .DeleteChuFang {
            width: 50px;
            height: 28px;
            border-radius: 4px;
            background: #f75444;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .ChuFang_YaoJi_add {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      padding-top: 20px;

      .ChuFang_YaoJi_add_btn {
        background: #409eff;
        color: #fff;
        height: 30px;
        width: 80px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }

      .ChuFang_YaoJi_add_dose {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-input-number {
          width: 75px !important;
          margin: 0 10px;
        }

        /deep/ .el-input-number--mini .el-input-number__decrease,
        .el-input-number--mini .el-input-number__increase {
          width: 20px !important;
        }

        /deep/ .el-input-number--mini .el-input__inner {
          padding-left: 25px !important;
        }
      }
    }

    .ChuFang_JianFu {
      width: 100%;
      height: 35%;
      display: flex;
      align-items: center;
      overflow-y: auto;
      margin-top: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .ChuFang_JianFu_title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      .ChuFang_JianFu_list {
        .ChuFang_JianFu_item {
          line-height: 16px;
          font-size: 12px;
        }
      }
    }

    .ChuFang_Nub {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.Search_listss {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  border-bottom: none;
  height: calc(100% - 36px);
  overflow: auto;

  .Search_item_scroll {
    height: calc(100%);
    overflow: auto;

    > div {
      border: none;
      border-right: 1px solid #dcdfe6;
    }
  }

  .Search_list_title {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    background: #f9f9f9;
    color: #999999;
  }

  .Search_item {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dcdfe6;

    .zufang_index {
      width: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
    }

    .zufang_title {
      display: flex;
      width: 13%;
      align-items: center;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
      padding: 20px 25px;
      box-sizing: border-box;
    }

    .zufang_info {
      width: 50%;
      padding: 15px;
      box-sizing: border-box;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
      line-height: 16px;

      .zufang_yaoji {
        display: flex;
        flex-wrap: wrap;

        /deep/ .el-checkbox__label {
          padding-left: 5px;

          > div {
            margin-left: 0 !important;
          }
        }

        > div {
          margin-right: 20px;
        }
      }

      .zufang_fangfa_title {
        font-size: 14px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 4px;
      }

      .zufang_fangfa {
        width: 100%;

        .zufang_fangfa_val {
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
        }
      }
    }

    .zufang_msg {
      width: 31%;
      height: 150px;
      // display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dcdfe6;
      line-height: 18px;
      overflow-y: auto;
      padding: 8px;
      box-sizing: border-box;
      white-space: pre-line;

      p {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.add_yaoji {
  width: 360px;
  height: 320px;
  margin: 0 auto;

  .yao_name {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .cz {
      border: 1px solid #ddd;
      color: #444;
      background: #f4f4f4;
      width: 88px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      letter-spacing: 2px;
      margin-left: 30px;
      cursor: pointer;
    }

    .xyb {
      background: #328ffe;
      color: #ffffff;
      width: 88px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      letter-spacing: 2px;
      margin-left: 30px;
      cursor: pointer;
    }

    .name {
      width: 80px;
    }

    .inputs {
      width: 180px;
    }

    .danwei {
      width: 180px;
      height: 36px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
    }
  }
}

.Diagnosis_YiZhu {
  height: 40% !important;
  border-bottom: 1px solid #dcdfe6;

  .DiagnosisInfo {
    padding: 20px !important;

    .YiZhu_input {
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }
}
</style>

<template>
	<div class="Case">
		<div class="CaseHerd">
			<div class="CaseHerd_date">
				<div class="CaseHerd_name">日期选择：</div>
				<el-date-picker v-model="CaseHerd_date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
			</div>
			<div class="CaseHerd_search">
				<div class="CaseHerd_name">关键词：</div>
				<el-input placeholder="请输入内容" clearable v-model="Keyword"></el-input>
			</div>
			<div class="SubmitSearch"><el-button type="primary" @click="GetList">立即搜索</el-button></div>
		</div>
		<div class="Tab">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="已完成" name="first"></el-tab-pane>
				<el-tab-pane label="未完成" name="second"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="CaseList">
			<el-table :data="tableData" border style="width: 100%" height="100%">
				<el-table-column prop="name" label="姓名" width="150"></el-table-column>
				<el-table-column prop="gender_text" label="性别" width="100"></el-table-column>
				<el-table-column prop="age" label="年龄" width="100"></el-table-column>
				<el-table-column prop="birth" label="出生日期"></el-table-column>
				<el-table-column prop="adminnickname" label="医师"></el-table-column>
				<el-table-column prop="createtime" label="创建时间"></el-table-column>
				<el-table-column fixed="right" label="操作">
					<template #default="scope">
						<div class="caozuo" v-if="TabType == 1">
							<el-button type="primary" size="small" @click="FindLook(scope.row)">查看</el-button>
							<!-- <el-button type="primary" size="small" @click="EditInfo(scope.row)">去完善</el-button> -->
							<el-button v-show="userInfo.group_id == 1" type="danger" size="small" @click="Delete(scope.row)">删除</el-button>
						</div>
						<div class="caozuo" style="justify-content: center;" v-if="TabType == 2">
							<el-button type="primary" size="small" @click="EditInfo(scope.row)">去完善</el-button>
							<el-button v-show="userInfo.group_id == 1" type="danger" size="small" @click="Delete(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页 -->
		<div class="Page" v-if="tableData.length != 0">
			<el-pagination
				background
				layout="prev, pager, next"
				:total="List_total"
				@current-change="casepagination"
				@prev-click="casepagination"
				@next-click="casepagination"
			></el-pagination>
		</div>
		<!-- 详情 -->
		<el-dialog title="详情" v-model="ServiceShow">
			<div class="Lndividual">
				<div class="Lndividual_from">
					<!-- 信息 -->
					<div class="MedicalInfo Medical">
						<div class="name">姓名</div>
						<div class="txt">{{ MedicalInfo.name }}</div>
						<div class="name">年龄</div>
						<div class="txt">{{ MedicalInfo.age }}</div>
						<div class="name">性别</div>
						<div class="txt">{{ MedicalInfo.gender_text }}</div>
						<div class="name">出生日期</div>
						<div class="txt">{{ MedicalInfo.birth }}</div>
					</div>
					<!-- 备注 -->
					<div class="MedicalRemarks Medical">
						<div class="name">备注</div>
						<div class="input">{{ MedicalInfo.summary }}</div>
					</div>
					<!-- 四诊信息 -->
					<div class="Diagnosis Medical">
						<div class="name">四诊信息</div>
						<div class="DiagnosisInfo">
							<div class="DiagnosisItem" v-for="(item, index) in MedicalInfo.bingzheng" :key="index">{{ item }}</div>
						</div>
					</div>
					<!-- 病机 -->
					<div class="Diagnosis Medicals">
						<div class="name div">病机</div>
						<!-- <div class="DiagnosisInfo">
							<div class="DiagnosisItems" v-for="(item, index) in MedicalInfo.bingji" :key="index">
								<div class="DiagnosisItems_title">方证{{ index + 1 }}：{{ item.name }}</div>
							</div>
						</div> -->
						<div class="DiagnosisInfo">
							<div class="DiagnosisItems" v-for="(item, index) in MedicalInfo.bingji" :key="index">
								<div class="DiagnosisItems_title">{{ item.name }} ( {{ item.percent }})</div>
								<div class="DiagnosisItems_info">
									<div class="DiagnosisItems_info_flex">
										<div class="DiagnosisItems_info_flex_name">六经:</div>
										<div class="DiagnosisItems_info_flex_txt">{{ item.liujing }};</div>
									</div>
									<div class="DiagnosisItems_info_flex">
										<div class="DiagnosisItems_info_flex_name">脏腑经络:</div>
										<div class="DiagnosisItems_info_flex_txt">{{ item.zangfu }};</div>
									</div>
									<div class="DiagnosisItems_info_flex">
										<div class="DiagnosisItems_info_flex_name">八纲六邪:</div>
										<div class="DiagnosisItems_info_flex_txt">{{ item.bagang }};</div>
									</div>
									<div class="DiagnosisItems_info_flex">
										<div class="DiagnosisItems_info_flex_name">治则:</div>
										<div class="DiagnosisItems_info_flex_txt">{{ item.zhize }};</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="Diagnosis Medicals ">
						<div class="name div">组方</div>
						<div class="DiagnosisInfo">
							<div class="DiagnosisItems" v-for="(item, index) in MedicalInfo.fangzheng" :key="index">
								<div class="DiagnosisItems_title">组方{{ index + 1 }}：{{ item.name }}</div>
							</div>
						</div>
					</div>
					<!-- 处方 -->
					<div class="Diagnosis Medicals Diagnosis_ChuFang">
						<div class="name div">处方</div>
						<div class="DiagnosisInfo">
							<div class="ChuFang_YaoJi">
								<div class="ChuFang_YaoJi_list">
									<div class="ChuFang_YaoJi_item" v-for="(item, index) in Fangji_json" :key="index">
										<div class="ChuFang_YaoJi_item_name">{{ item }}</div>
									</div>
								</div>
							</div>
							<!-- <div class="ChuFang_JianFu">
								<div class="ChuFang_JianFu_title">煎服法:</div>
								<div class="ChuFang_JianFu_list">{{ Jianfu_json }}</div>
							</div> -->
							<div class="ChuFang_Nub">共 {{ MedicalInfo.fangji.num }} 剂</div>
						</div>
					</div>
					<!-- 医嘱 -->
					<div class="Diagnosis Medicals Diagnosis_YiZhu">
						<div class="name div">医嘱</div>
						<div class="DiagnosisInfo">
							<textarea v-model="Comment" placeholder="医生可自主添加相关备注" class="YiZhu_input" :cols="4" wrap="hard" disabled></textarea>
							<!-- <input type="text" v-model="Comment" placeholder="医生可自主添加相关备注" class="YiZhu_input" disabled="true" /> -->
						</div>
					</div>
					<!-- 医师 -->
					<div class="Physician Medical">
						<div class="PhysicianInfo">
							<div class="name">医师:</div>
							<div class="txt">{{ ServiceYs }}</div>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="operation operation_top">
				<!-- <div class="syb" @click="GetPrevious">上一步</div> -->
				<div class="xyb" @click="GetNext">导出医案</div>
				<!-- <div class="cz" @click="Reset">返回医案</div> -->
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
	data() {
		return {
			activeName: 'first',
			TabType: 1,
			List_Page: 1,
			List_total: null,
			Keyword: '',
			tableData: [],
			CaseHerd_date: '',
			ServiceShow: false,
			ServiceId: '',
			ServiceYs: '',
			//
			MedicalInfo: [],
			Fangji_json: [],
			Jianfu_json: '',
			Comment: ''
		};
	},
	created() {
		this.GetList();
	},
	computed: {
		...mapState(['userInfo'])
	},
	methods: {
		casepagination(e) {
			let page = e;
			this.List_Page = page;
			this.GetList();
		},
		// 查看详情
		FindLook(row) {
			this.ServiceShow = true;
			this.ServiceId = row.id;
			this.ServiceYs = row.adminnickname;
			this.ExportDetails();
		},
		// tab 切换
		handleClick(tab, event) {
			let type = tab.props.name;
			if (type == 'second') {
				this.TabType = 2;
			} else {
				this.TabType = 1;
			}
			this.GetList();
		},
		// 列表
		async GetList() {
			let url;
			if (this.TabType == 1) {
				url = '/api/yian/list';
			} else {
				url = '/api/yian/list2';
			}
			url = url + '?page=' + this.List_Page + '&keyword=' + this.Keyword;
			let Date = this.CaseHerd_date;
			let DateStart = '';
			let DateEnd = '';
			if (Date) {
				DateStart = this.formatDate(Date[0], 1);
				DateEnd = this.formatDate(Date[1], 1);
				DateStart = DateStart.slice(0, 10);
				DateEnd = DateEnd.slice(0, 10);
				url = url + '&time=' + DateStart + '|' + DateEnd;
			}
			let { data } = await this.$axios.get(url, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			// if (data.code == 401)	return this.CleanLogin();
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			let tableData = data.data.data;
			for (let x = 0; x < tableData.length; x++) {
				let createtime = this.formatDate(tableData[x].createtime);
				tableData[x].createtime = createtime;
				tableData[x].adminnickname = tableData[x].admin.nickname;
			}
			this.tableData = tableData;
			this.List_total = data.data.total;
			this.List_Page = data.data.current_page;
		},
		// 删除
		async Delete(item) {
			let result = await this.ElmOpen('是否要删除该医案!', '确定');
			if (result == 'confirm') {
				// 删除
				this.PostDelete(item);
			}
		},
		async PostDelete(item) {
			let { data } = await this.$axios.post(
				'/api/yian/del',
				{
					id: item.id
				},
				{
					headers: {
						token: JSON.parse(localStorage.getItem('userinfo')).token
					}
				}
			);
			if (data.code == 401) return this.CleanLogin();
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			this.ElmMsg('删除成功', 2);
			this.GetList();
		},
		// 医案详情
		async ExportDetails() {
			let { data } = await this.$axios.get('/api/yian/detail?id=' + this.ServiceId, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) return this.CleanLogin();
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			let Info = data.data;
			//   for (let x = 0; x < Info.fangzheng.length; x++) {
			//     Info.fangzheng[x].jinji_json = JSON.parse(Info.fangzheng[x].jinji_json);
			//     Info.fangzheng[x].leifang_json = JSON.parse(
			//       Info.fangzheng[x].leifang_json
			//     );
			//     Info.fangzheng[x].fangji_json = JSON.parse(
			//       Info.fangzheng[x].fangji_json
			//     );
			//     Info.fangzheng[x].jianfu_json = JSON.parse(
			//       Info.fangzheng[x].jianfu_json
			//     );
			//   }
			let Fangji_json = Info.fangji.fangji_json;
			let Jianfu_json = Info.fangji.jianfu_json;
			var b = Fangji_json.replace(/&quot;/g, '').replace(/\[|]/g, '');
			var c = Jianfu_json.replace(/&quot;/g, '').replace(/\[|]/g, '');
			this.Fangji_json = b.split(',');
			this.Jianfu_json = c;
			this.MedicalInfo = Info;
			this.Comment = Info.fangji.comment;
		},
		// 导出案例
		async GetNext() {
			let { data } = await this.$axios.get('/api/yian/export?id=' + this.ServiceId, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) return this.CleanLogin();
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			//  导出
			window.open(this.baseURL + data.data);
		},
		// 完善信息
		EditInfo(row) {
			// console.log(row);
			// 根据 step 去判断步骤
			if (this.TabType == 1) {
				this.$router.push({
					name: 'Prescription',
					params: { id: row.id, type: 2 }
				});
				return false;
			}
			if (!row.step) {
				this.$router.push({
					name: 'Lndividual',
					params: { id: row.id, type: 2 }
				});
			}
			if (row.step == '四诊采集') {
				this.$router.push({
					name: 'Lndividual',
					params: { id: row.id, type: 2 }
				});
			}
			if (row.step == '病机辨析') {
				this.$router.push({
					name: 'Pathogenesis',
					params: { id: row.id, type: 2 }
				});
			}
			if (row.step == '方证辨析') {
				this.$router.push({
					name: 'Group',
					params: { id: row.id, type: 2 }
				});
			}
			if (row.step == '方剂拟订') {
				this.$router.push({
					name: 'Prescription',
					params: { id: row.id, type: 2 }
				});
			}
		}
	}
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__close {
	font-size: 20px;
}
/deep/ .el-overlay {
	left: 229px !important;
}
/deep/ .el-dialog {
	top: 50% !important;
	transform: translateY(-50%);
	margin-top: 0 !important;
	width: 98% !important;
}
/deep/ .el-overlay {
	overflow: hidden !important;
}
/deep/ .cell {
	font-size: 14px !important;
}
/deep/ .el-input__icon {
	font-size: 18px !important;
}
/deep/ .el-input__inner {
	font-size: 16px;
}
.ServiceShow {
	width: 100%;
}
.Case {
	width: 100%;
	padding: 20px;
	padding-bottom: 0;
	box-sizing: border-box;
	background: #fff;
	.CaseHerd {
		width: 100%;
		display: flex;
		.CaseHerd_date {
			display: flex;
			align-items: center;
			.CaseHerd_name {
				font-size: 16px;
				margin-right: 10px;
			}
		}
		.CaseHerd_search {
			display: flex;
			align-items: center;
			.CaseHerd_name {
				font-size: 16px;
				margin-right: 10px;
				margin-left: 20px;
				min-width: 70px;
			}
		}
		.SubmitSearch {
			margin-left: 20px;
		}
	}
	.Tab {
		margin-top: 20px;
	}
	.CaseList {
		width: 100%;
		height: calc(100vh - 320px);
		/deep/ .is-scrolling-none::-webkit-scrollbar {
			display: none;
		}
		/deep/ .el-table th.gutter {
			display: none;
			width: 0;
		}
		/deep/ .el-table colgroup col[name='gutter'] {
			display: none;
			width: 0;
		}
		/deep/ .el-table__body {
			width: 100% !important;
		}
		/deep/ .el-table__fixed-right-patch {
			display: none;
		}
		/deep/ .el-table__fixed-right {
			right: 0px !important;
		}
	}
	.Page {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
		height: 50px;
		align-items: center;
	}
}
.caozuo {
	display: flex;
}
.zt_active {
	color: #fff !important;
	background: #f75444 !important;
}
.zt_active1 {
	color: red !important;
}
.add_yaoji {
	width: 360px;
	height: 320px;
	margin: 0 auto;
	.yao_name {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.cz {
			border: 1px solid #ddd;
			color: #444;
			background: #f4f4f4;
			width: 88px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			letter-spacing: 2px;
			margin-left: 30px;
			cursor: pointer;
		}
		.xyb {
			background: #328ffe;
			color: #ffffff;
			width: 88px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			letter-spacing: 2px;
			margin-left: 30px;
			cursor: pointer;
		}
		.name {
			width: 80px;
		}
		.inputs {
			width: 180px;
		}
		.danwei {
			width: 180px;
			height: 36px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			border-radius: 4px;
			border: 1px solid #dcdfe6;
		}
	}
}
.Lndividual {
	width: 100%;
	max-height: 500px;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	.Lndividual_from {
		width: 100%;
		background: #ffffff;
		padding: 30px;
		padding-top: 0;
		box-sizing: border-box;
		// padding-bottom:0 ;
		// height: calc(100vh - 100px);
		.MedicalInfo {
			height: 38px;
		}
		.Medical {
			display: flex;
			align-items: center;
			border-right: 1px solid #dcdfe6;
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 1px solid #dcdfe6;
				box-sizing: border-box;
				letter-spacing: 2px;
				height: 100%;
				font-size: 14px;
			}
			.name {
				background: #f5f5f5;
				color: #333;
				width: 140px;
				height: 100%;
				font-weight: 600;
				font-size: 16px;
			}
			.name:first-child {
				border: none;
			}
			.txt {
				// width: 150px;
				flex: 1;
				font-size: 14px;
			}
			.input {
				border-right: none;
			}
		}
		.MedicalRemarks {
			height: 66px;
			.input {
				width: calc(100% - 140px);
				font-size: 16px;
			}
		}
		.Diagnosis_YiZhu {
			height: 120px !important;
			border-bottom: 1px solid #dcdfe6;
			.DiagnosisInfo{
				padding: 20px !important;
				.YiZhu_input {
					width: 100%;
					height: 80px;
				}
			}
		}
		.Diagnosis_ChuFang {
			height: calc(100vh - 700px) !important;
			background: #fafafa;
			.ChuFang_YaoJi {
				// height: 62%;
				display: flex;
				justify-content: space-between;
				.ChuFang_YaoJi_list {
					width: calc(100% - 125px);
					display: flex;
					flex-wrap: wrap;
					// overflow: auto;
					.ChuFang_YaoJi_item {
						display: flex;
						align-items: center;
						margin-top: 14px;
						// width: 400px;
						.ChuFang_YaoJi_item_name {
							font-size: 14px;
							font-weight: 600;
							margin-right: 10px;
							// width: 70px;
						}
						.ChuFang_YaoJi_item_num {
							.el-input-number {
								width: 130px !important;
							}
						}
						.ChuFang_YaoJi_item_dropdown {
							width: 60px;
							height: 36px;
							.xianshi {
								width: 100%;
								height: 100%;
								border: 1px solid #dcdfe6;
								margin-left: 14px;
								display: flex;
								justify-content: center;
								align-items: center;
								// background: #409eff;
								// color: #fff;
								color: #333;
								border-radius: 4px;
								i {
									margin-left: 4px;
								}
							}
							/deep/ .el-dropdown {
								width: 60px !important;
								height: 100%;
							}
						}
						.DeleteChuFang {
							width: 60px;
							height: 38px;
							border-radius: 4px;
							background: #f75444;
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-left: 30px;
							cursor: pointer;
						}
					}
				}
				.ChuFang_YaoJi_add {
					width: 100px;
					display: flex;
					justify-content: flex-end;
					flex-direction: column;
					align-items: center;
					div {
						// background: #409eff;
						color: #fff;
						padding: 6px 25px;
						height: 25px;
						width: 50px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.ChuFang_JianFu {
				width: 100%;
				// height: 30%;
				margin-top: 20px;
				.ChuFang_JianFu_title {
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
				}
				.ChuFang_JianFu_list {
					margin-top: 8px;
					.ChuFang_JianFu_item {
						line-height: 16px;
						font-size: 12px;
					}
				}
			}
			.ChuFang_Nub {
				margin-top: 20px;
				font-size: 14px;
				font-weight: 600;
			}
		}
		.Diagnosis {
			height: 100% !important;
			// padding: 20px 0;
			background: #f5f5f5;
			border-right: 1px solid #dcdfe6;
			.DiagnosisInfo {
				width: calc(100% - 140px);
				overflow: auto;
				height: 100%;
				justify-content: flex-start;
				padding: 20px;
				box-sizing: border-box;
				flex-wrap: wrap;
				border-top: 1px solid #dcdfe6;
				background: #ffffff;
				border-right: none;
				input {
					font-size: 14px;
				}
				.DiagnosisItems {
					width: 100%;
					margin-top: 0 !important;
					margin-bottom: 10px;
					.DiagnosisItems_title {
						width: 100%;
						display: flex;
						font-size: 14px;
						font-weight: 100 !important;
						// margin-top: 10px;
					}
					.DiagnosisItems_zufang {
						display: flex;
						flex-wrap: wrap;
						width: 100%;
						height: 32px;
					}
					.DiagnosisItems_zufang_title {
						font-size: 14px;
						font-weight: 600;
						margin-top: 4px;
						margin-bottom: 4px;
					}
				}
				.DiagnosisItem {
					padding: 5px 12px;
					box-sizing: border-box;
					//   background: #409eff;
					color: #ffffff;
					height: 30px;
					border-radius: 4px;
					margin-right: 8px;
					position: relative;
					margin-top: 4px !important;
					margin-bottom: 4px !important;
					i {
						position: absolute;
						top: -5px;
						right: -5px;
						background: red;
						border-radius: 50%;
						width: 15px;
						height: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						padding-left: 2px;
						box-sizing: border-box;
						font-size: 12px;
					}
				}
			}
		}
		.Medicals {
			display: flex;
			align-items: center;
			border-right: 1px solid #dcdfe6;
			.div {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #f4f4f4;
				box-sizing: border-box;
				letter-spacing: 2px;
				height: 100%;
				font-size: 14px;
			}
			.name {
				background: #f5f5f5;
				color: #333;
				width: 140px;
				font-weight: 600;
			}
			.txt {
				width: 150px;
			}
		}
		.Physician {
			height: 36px;
			display: flex;
			justify-content: flex-end;
			border-left: 1px solid #f4f4f4;
			border-bottom: 1px solid #f4f4f4;
			border-top: 1px solid #f4f4f4;
			.PhysicianInfo {
				display: flex;
				height: 100%;
				div {
					border-bottom: none !important;
					border-top: none !important;
					border-right: none !important;
				}
			}
		}
	}
}
.operation {
	width: 100% !important;
}
.operation_top {
	// margin-top: 30px;
}
.tuijian_list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.Search_listss {
	width: 100%;
	border: 1px solid #dcdfe6;
	margin-top: 30px;
	height: calc(100vh - 680px);
	overflow: auto;
	.Search_list_title {
		height: 36px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		box-sizing: border-box;
		background: #f9f9f9;
		color: #999999;
	}
	.Search_item {
		width: 100%;
		display: flex;
		border-bottom: 1px solid #dcdfe6;
		.zufang_index {
			width: 10%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
		}
		.zufang_title {
			display: flex;
			width: 15%;
			align-items: center;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
			padding: 20px 30px;
			box-sizing: border-box;
		}
		.zufang_info {
			width: 65%;
			padding: 15px;
			box-sizing: border-box;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
			.zufang_fangfa_title {
				font-size: 14px;
				font-weight: 600;
				margin-top: 4px;
				margin-bottom: 4px;
			}
		}
		.zufang_msg {
			width: 10%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #dcdfe6;
		}
	}
}
</style>

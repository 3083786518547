<template>
  <div class="Lndividual">
    <div class="Lndividual_from">
      <div class="Lndividual_from_box">
        <div class="Lndividual_from_box_left">
          <!-- 信息 -->
          <div class="MedicalInfo Medical">
            <div class="name">姓名</div>
            <div class="txt">{{ MedicalInfo.name }}</div>
            <div class="name">年龄</div>
            <div class="txt">{{ MedicalInfo.age }}</div>
            <div class="name">性别</div>
            <div class="txt">{{ MedicalInfo.gender_text }}</div>
            <div class="name">出生日期</div>
            <div class="txt">{{ MedicalInfo.birth }}</div>
          </div>
          <div class="Lndividual_from_scroll">
            <!-- 备注 -->
            <div class="MedicalRemarks Medical beizhu">
              <div class="name">备注</div>
              <div class="input">{{ MedicalInfo.summary }}</div>
            </div>
            <!-- 四诊信息 -->
            <div class="Diagnosis Medical sizhen">
              <div class="name">四诊信息</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItem"
                  v-for="(item, index) in DiagnosisArr"
                  :key="index"
                >
                  {{ item.name }}
                  <i
                    class="el-icon-close"
                    @click="DeleteDiagnosisInfo(item, 2)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <!-- 医师 -->
          <div class="Physician Medical">
            <div class="PhysicianInfo">
              <div class="name">医师:</div>
              <div class="txt">{{ userInfo.nickname }}</div>
            </div>
          </div>
        </div>
        <!-- 操作 -->
        <div class="operation operation_row operation_top">
          <div class="syb" @click="GetPrevious">上一步</div>
          <div class="cz" @click="Reset">重置</div>
          <div class="xyb" @click="GetNext">下一步</div>
        </div>
      </div>
      <!-- 搜索列表 -->
      <div class="List">
        <div class="List_Info">
          <div class="List_Title">
            <div class="List_Title_cont">
              <div
                class="List_Title_tab"
                v-for="(item, index) in MedicalType"
                :key="index"
                @click="CountHeight(index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="List_Searxh">
              <div class="List_Searxh_Right">
                <el-input
                  size="small"
                  v-model="Search"
                  clearable
                  placeholder="请输入四诊信息"
                ></el-input>
                <div class="Submit" @click="InputDiagnosisInfo">快速提交</div>
              </div>
            </div>
          </div>
          <div class="List_data">
            <div
              class="List_item"
              v-for="(item, index) in MedicalType"
              :key="index"
            >
              <div class="name">{{ item.name }}</div>
              <div class="choice">
                <div
                  class="choice-box"
                  v-for="(items, indexs) in item.childlist"
                  :key="indexs"
                >
                  <div
                    class="choice_flex"
                    v-for="(itemss, indexss) in items.childlist"
                    :key="indexss"
                  >
                    <el-checkbox
                      v-model="itemss.choice"
                      @change="ChoiceDiagnosis(items, index, itemss, indexs)"
                      >{{ itemss.name }}</el-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      MedicalInfo: [],
      DiagnosisArr: [], //选中的四诊信息
      MedicalType: [],
      Search: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (!this.$route.params.id) return this.$router.go(-1);
    this.GetMedicalInfo();
    this.GetMedicalType();
    this.$nextTick(() => {
      setTimeout(() => {
        let msg = document.getElementsByClassName("Lndividual_from_scroll")[0];
        let scrollHei = document.getElementsByClassName("beizhu")[0];
        msg.scrollTop = scrollHei.scrollHeight;
      }, 200);
    });
  },
  methods: {
    // 计算距离
    CountHeight(index) {
      // 获取 标签距离顶部的距离
      let tab = document.getElementsByClassName("List_item");
      let Height_top = tab[index].offsetTop;
      let Top = document.getElementsByClassName("List_Title")[0].offsetTop;
      // 让滚动条滚动
      let juli = Height_top - Top - 44;
      // console.log(juli, "juli");
      let data = document.getElementsByClassName("List_data")[0];
      data.scrollTop = juli;
    },
    //
    ChoiceDiagnosis(item, index, items, indexs) {
      if (items.choice) {
        this.MedicalType[index].childlist[indexs].choice = true;
      } else {
        this.MedicalType[index].childlist[indexs].choice = false;
      }
      let type = items.choice ? 1 : 2;
      let obj = {};
      obj.name = items.name;
      obj.index = index;
      obj.indexs = indexs;
      this.CountDiagnosisInfo(obj, type);
    },
    //
    CountDiagnosisInfo(obj, type) {
      if (type == 1) {
        this.PushDiagnosisInfo(obj);
      } else {
        this.DeleteDiagnosisInfo(obj);
      }
    },
    // 添加
    PushDiagnosisInfo(obj, type) {
      let DiagnosisInfo = this.DiagnosisArr;
      if (DiagnosisInfo.length != 0) {
        for (let x = 0; x < DiagnosisInfo.length; x++) {
          if (DiagnosisInfo[x].name != obj.name) {
            if (type == "add") {
              if (this.MedicalType.length > 0) {
                for (let i in this.MedicalType) {
                  for (let a in this.MedicalType[i].childlist) {
                    if (this.MedicalType[i].childlist[a].name == obj.name) {
                      this.MedicalType[i].childlist[a].choice = true;
                    }
                  }
                }
              }
            }
            return DiagnosisInfo.push(obj);
          }
        }
      } else {
        DiagnosisInfo.push(obj);
        if (type == "add") {
          if (this.MedicalType.length > 0) {
            for (let i in this.MedicalType) {
              for (let a in this.MedicalType[i].childlist) {
                if (this.MedicalType[i].childlist[a].name == obj.name) {
                  this.MedicalType[i].childlist[a].choice = true;
                }
              }
            }
          }
        }
      }
      this.DiagnosisArr = DiagnosisInfo;
    },
    // 删除
    DeleteDiagnosisInfo(obj, type = 1) {
      let DiagnosisInfo = this.DiagnosisArr;
      let Num;
      if (DiagnosisInfo.length != 0) {
        for (let x = 0; x < DiagnosisInfo.length; x++) {
          if (DiagnosisInfo[x].name == obj.name) {
            Num = x;
          }
        }
      }
      DiagnosisInfo.splice(Num, 1);
      this.DiagnosisArr = DiagnosisInfo;
      //
      if (type == 2) {
        if (obj.index == 0) {
          return (this.MedicalType[obj.index].childlist[
            obj.indexs
          ].choice = false);
        }
        if (!obj.index) return;
        this.MedicalType[obj.index].childlist[obj.indexs].choice = false;
      }
    },
    // 快速提交
    InputDiagnosisInfo() {
      if (!this.Search) {
        return this.ElmMsg("四诊信息不能为空", 1);
      }
      for (let x = 0; x < this.DiagnosisArr.length; x++) {
        if (this.DiagnosisArr[x].name == this.Search) {
          return this.ElmMsg("已有该四诊信息,无需提交!");
        }
      }
      let obj = {};
      obj.name = this.Search;
      this.PushDiagnosisInfo(obj, "add");
      this.Search = "";
    },
    // 上一步
    GetPrevious() {
      this.$router.push({
        name: "Process",
        params: { id: this.$route.params.id },
      });
    },
    // 下一步
    async GetNext() {
      let DiagnosisArr = this.DiagnosisArr;
      let arr = [];
      for (let x = 0; x < DiagnosisArr.length; x++) {
        arr.push(DiagnosisArr[x].name);
      }
      if (arr.length == 0) return this.ElmMsg("请选择四诊信息!");
      arr = arr.join(",");
      let { data } = await this.$axios.post(
        "/api/yian/set_bingzheng",
        {
          yian_id: this.$route.params.id,
          category: arr,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$router.push({
        name: "Pathogenesis",
        params: { id: this.$route.params.id },
      });
    },
    // 重置
    Reset() {
      this.Search = "";
      this.DiagnosisArr = [];
      this.GetMedicalType("reset");
    },
    async GetMedicalInfo() {
      let { data } = await this.$axios.get(
        "/api/yian/get_basic?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.MedicalInfo = data.data;
    },
    async GetMedicalType(type) {
      let { data } = await this.$axios.get("/api/yian/get_bingzheng_category", {
        headers: {
          token: JSON.parse(localStorage.getItem("userinfo")).token,
        },
      });
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 1) {
        let MedicalType = data.data;
        for (let x = 0; x < MedicalType.length; x++) {
          if (MedicalType[x].childlist) {
            for (let y = 0; y < MedicalType[x].childlist.length; y++) {
              for (let j = 0; j < MedicalType[y].childlist.length; j++) {
                MedicalType[y].childlist[j].choice = false;
              }
            }
          }
        }
        this.MedicalType = MedicalType;
        if (type != "reset") {
          this.GetFindPathogenesis();
        }
      }
    },
    // 查看病机
    async GetFindPathogenesis() {
      let { data } = await this.$axios.get(
        "/api/yian/get_bingzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.data.length == 0) return;
      // 改变数据 计算
      let DiagnosisArr = data.data;
      let arr = [];
      let MedicalType = this.MedicalType;
      for (let i = 0; i < MedicalType.length; i++) {
        let parent = MedicalType[i];
        for (let j = 0; j < parent.childlist.length; j++) {
          let child = parent.childlist[j];
          for (let k = 0; k < child.childlist.length; k++) {
            let grandchild = child.childlist[k];
            for (let l = 0; l < DiagnosisArr.length; l++) {
              if (DiagnosisArr[l] == grandchild.name) {
                let obj = {};
                obj.name = DiagnosisArr[l];
                obj.index = i;
                obj.indexs = j;
                obj.indexss = k;
                arr.push(obj);
                // 勾选
                grandchild.choice = true;
              }
            }
          }
        }
      }
      this.DiagnosisArr = arr;
    },
  },
};
</script>
<style lang="less">
.Lndividual {
  width: 100%;
  height: 100%;
  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    // height: calc(100vh - 100px);
    .Lndividual_from_box {
      height: 30%;
      .Lndividual_from_scroll {
        height: calc(100% - 38px - 38px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .sizhen {
          flex: 1;
          height: auto !important;
          .name {
            height: auto;
          }
        }
      }
    }
    .MedicalInfo {
      height: 38px;
    }
    .Medical {
      display: flex;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }
      .txt {
        width: 150px;
      }
    }
    .MedicalRemarks {
      height: 30px;
      .input {
        width: calc(100% - 140px);
      }
    }
    .beizhu {
      // min-height: 30px !important;
      height: auto !important;
      .name {
        min-height: 30px !important;
      }
      .input {
        padding: 10px;
      }
    }
    .Diagnosis {
      height: 80px;
      .DiagnosisInfo {
        width: calc(100% - 140px);
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        border-bottom: none;
        &::-webkit-scrollbar {
          display: none;
        }
        .DiagnosisItem {
          padding: 5px 12px;
          box-sizing: border-box;
          background: #409eff;
          color: #ffffff;
          height: 30px;
          border-radius: 4px;
          margin-right: 8px;
          position: relative;
          margin-top: 8px;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;
            box-sizing: border-box;
            font-size: 12px;
          }
        }
      }
    }
    .Physician {
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      .PhysicianInfo {
        display: flex;
        height: 100%;
        div {
          border-bottom: none !important;
          border-top: none !important;
        }
      }
    }
    .operation {
      // width: 100% !important;
    }
    .operation_row {
      display: block;
      width: 10% !important;
      div {
        margin-top: 15px;
      }
    }
    .operation_top {
      // margin-top: 30px;
      margin-top: 2%;
    }
    .List {
      width: 100%;
      height: 65%;
      .List_Searxh {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 16px;
        .List_Searxh_Right {
          display: flex;
          .Submit {
            width: 100px;
            height: 32px;
            background: #409eff;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .List_Info {
        width: 100%;
        height: 100%;
        .List_Title {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.1);
          .List_Title_cont {
            display: flex;
            flex-wrap: wrap;
            .List_Title_tab {
              padding: 5px 16px;
              box-sizing: border-box;
              // margin-bottom: 6px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
        .List_data {
          width: 100%;
          height: calc(100% - 44px);
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .List_item {
            // height: 44px;
            display: flex;
            width: 100%;
            .name {
              background: #f5f5f5;
              color: #333;
              width: 140px;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #f4f4f4;
              box-sizing: border-box;
              letter-spacing: 2px;
            }
            .choice {
              width: calc(100% - 140px);
              display: flex;
              flex-direction: column;
              height: 100%;
              padding: 20px;
              box-sizing: border-box;
              // align-items: center;
              border: 1px solid #f4f4f4;
              flex-wrap: wrap;
              overflow: auto;
              .choice-box {
                display: flex;
                flex-wrap: wrap;
                .choice_flex {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  margin-right: 10px;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import User from "../views/User.vue"
import Process from "../views/Process.vue"
import Case from "../views/Case.vue"
import Login from "../views/Login.vue"
import Lndividual from "../views/Lndividual.vue"
import Pathogenesis from "../views/Pathogenesis.vue"
import Group from "../views/Group.vue"
import Prescription from "../views/Prescription.vue"
import Formula from "../views/Formula.vue"
import Export from "../views/Export.vue"
import Details from "../views/Details.vue"
import userList from "../views/userList.vue"
import editIp from "../views/editIp.vue"
const routes = [{
    path: "/",
    name: "User",
    component: User
},
{
    path: "/Process",
    name: "Process",
    component: Process
}, {
    path: "/Case",
    name: "Case",
    component: Case
}, {
    path: "/Login",
    name: "Login",
    component: Login
}, {
    path: "/Lndividual",
    name: "Lndividual",
    component: Lndividual
}, {
    path: "/Pathogenesis",
    name: "Pathogenesis",
    component: Pathogenesis
}, {
    path: "/Group",
    name: "Group",
    component: Group
}, {
    path: "/Prescription",
    name: "Prescription",
    component: Prescription
}, {
    path: "/Formula",
    name: "Formula",
    component: Formula
}, {
    path: "/Export",
    name: "Export",
    component: Export
}, {
    path: "/Details",
    name: "Details",
    component: Details
}, {
    path: "/userList",
    name: "userList",
    component: userList
}, {
    path: "/editIp",
    name: "editIp",
    component: editIp
}
]
const router = createRouter({
    history: createWebHashHistory(),
    // createWebHistory(process.env.BASE_URL),
    routes
})
export default router
export default {
    data() {
        return {

        };
    },
    computed: {
        // 时间转换
        add0() {
            return (m) => {
                return m < 10 ? "0" + m : m;
            };
        },
        formatDate() {
            return (shijianchuo, type = '0') => {
                var shijian = type == 0 ? shijianchuo * 1000 : shijianchuo
                var time = new Date(shijian);
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                var h = time.getHours();
                var mm = time.getMinutes();
                var s = time.getSeconds();
                let date =
                    y +
                    "-" +
                    this.add0(m) +
                    "-" +
                    this.add0(d) +
                    " " +
                    this.add0(h) +
                    ":" +
                    this.add0(mm) +
                    ":" +
                    this.add0(s);
                return date;
            };
        },
    },
    methods: {
        ElmOpen(title, confirmButtonText = '确定', cancelButtonText = '取消', type = 'warning') {
            return new Promise((resolve) => {
                this.$confirm(title, "提示", {
                    confirmButtonText,
                    cancelButtonText,
                    type,
                })
                    .then((result) => {
                        resolve(result)
                    })
                    .catch(() => { });
            })
        },
        ElmMsg(message, type = 1, showClose = false, center = false) {
            let messagetype
            if (type == 1) {
                messagetype = 'error'
            } else if (type == 2) {
                messagetype = 'success'
            } else if (type == 3) {
                messagetype = 'warning'
            }
            this.$message({
                message,
                type: messagetype,
                showClose,
                center,
            });
        },
        // 清除登录
        CleanLogin() {
            localStorage.removeItem('userinfo')
            this.$router.push("/Login")
        },
    },
    mounted() {

    },
    created() {

    },
    unmounted() {

    }
};
<template>
	<div class="Lndividual">
		<div class="Lndividual_from">
			<!-- 信息 -->
			<div class="MedicalInfo Medical">
				<div class="name">姓名</div>
				<div class="txt">{{ MedicalInfo.name }}</div>
				<div class="name">年龄</div>
				<div class="txt">{{ MedicalInfo.age }}</div>
				<div class="name">性别</div>
				<div class="txt">{{ MedicalInfo.gender_text }}</div>
				<div class="name">出生日期</div>
				<div class="txt">{{ MedicalInfo.birth }}</div>
			</div>
			<!-- 备注 -->
			<div class="MedicalRemarks Medical">
				<div class="name">备注</div>
				<div class="input">{{ MedicalInfo.summary }}</div>
			</div>
			<!-- 四诊信息 -->
			<div class="Diagnosis Medical">
				<div class="name">四诊信息</div>
				<div class="DiagnosisInfo">
					<div class="DiagnosisItem" v-for="(item, index) in DiagnosisArr" :key="index">{{ item }}</div>
				</div>
			</div>
			<!-- 病机 -->
			<div class="Diagnosis Medicals">
				<div class="name div">病机</div>
				<div class="DiagnosisInfo">
					<div class="DiagnosisItems" v-for="(item, index) in PathogenesisArr" :key="index">
						<div class="DiagnosisItems_title">方证{{ index + 1 }}：{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div class="Diagnosis Medicals ">
				<div class="name div">组方</div>
				<div class="DiagnosisInfo">
					<div class="DiagnosisItems" v-for="(item, index) in GroupArr" :key="index">
						<div class="DiagnosisItems_title">组方{{ index + 1 }}：{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div class="Diagnosis Medicals">
				<div class="name div">方剂</div>
				<div class="DiagnosisInfo">
					<div class="DiagnosisItems">
						<div class="DiagnosisItems_zufang">
							<div v-for="(item, index) in YaoJiArr" :key="index">{{ item.name }}{{ item.num }}{{ item.unit }}</div>
						</div>
						<div class="DiagnosisItems_zufang_title">煎服法:</div>
						<div class="DiagnosisItems_zufang_list">
							<div class="" v-for="(item, index) in JianFuArr" :key="index">
								<div>{{ index + 1 }}、 {{ item.value }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 处方 -->
			<div class="Diagnosis Medicals Diagnosis_ChuFang">
				<div class="name div">处方</div>
				<div class="DiagnosisInfo">
					<div class="ChuFang_YaoJi">
						<div class="ChuFang_YaoJi_list">
							<div class="ChuFang_YaoJi_item" v-for="(item, index) in ChuFangList" :key="index">
								<div class="ChuFang_YaoJi_item_name">{{ item.name }}:</div>
								<div class="ChuFang_YaoJi_item_num"><el-input-number v-model="item.nums" @change="handleChange" :min="1"></el-input-number></div>
								<div class="ChuFang_YaoJi_item_dropdown">
									<el-dropdown @command="handleCommand">
										<div class="xianshi">
											{{ item.unit }}
											<i class="el-icon-arrow-down el-icon--right"></i>
										</div>
										<template #dropdown>
											<el-dropdown-menu>
												<el-dropdown-item v-for="(items, indexs) in UnitList" :key="indexs" :command="items" @click="handleIndex(index, items)">
													{{ items.name }}
												</el-dropdown-item>
											</el-dropdown-menu>
										</template>
									</el-dropdown>
								</div>
								<div class="DeleteChuFang" @click="DeleteChuFang(item, index)">删除</div>
							</div>
						</div>
						<div class="ChuFang_YaoJi_add"><div @click="AddYaoJi">自主添加</div></div>
					</div>
					<div class="ChuFang_JianFu">
						<div>
							<div class="ChuFang_JianFu_title">煎服法:</div>
							<div class="ChuFang_JianFu_list">
								<div class="ChuFang_JianFu_item" v-for="(item, index) in JianFuArr" :key="index">{{ index + 1 }}、 {{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="ChuFang_Nub">共 {{ ChuFangList.length }} 味</div>
				</div>
			</div>
			<!-- 医嘱 -->
			<div class="Diagnosis Medicals Diagnosis_YiZhu">
				<div class="name div">医嘱</div>
				<div class="DiagnosisInfo"><input type="text" v-model="Search" placeholder="医生可自主添加相关备注" class="YiZhu_input" /></div>
			</div>
			<!-- 医师 -->
			<div class="Physician Medical">
				<div class="PhysicianInfo">
					<div class="name">医师:</div>
					<div class="txt">{{ userInfo.username }}</div>
				</div>
			</div>
			<!-- 操作 -->
			<div class="operation operation_top">
				<div class="syb" @click="GetPrevious">上一步</div>
				<div class="cz" @click="Reset">重置</div>
				<div class="xyb" @click="GetNext">提交保存</div>
			</div>
		</div>
		<!-- 自主添加弹框 -->
		<el-dialog title="添加药剂" v-model="YaoJiShow" style="width:30%;">
			<div class="add_yaoji">
				<div class="yao_name">
					<div class="name">药名</div>
					<div class="inputs"><el-input v-model="YaoJiInfo.name" placeholder="请输入药名" clearable></el-input></div>
				</div>
				<div class="yao_name">
					<div class="name">剂量</div>
					<div class="inputs"><el-input-number v-model="YaoJiInfo.nums" @change="handleChange" :min="1"></el-input-number></div>
				</div>
				<div class="yao_name">
					<div class="name">单位</div>
					<div class="inputs">
						<el-dropdown @command="YaoJiCommand">
							<div class="danwei">
								{{ YaoJiInfo.unit || '请选择单位' }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</div>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item v-for="(item, index) in UnitList" :key="index" :command="item">{{ item.name }}</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
				<div class="yao_name">
					<div class="xyb" @click="add_push">确定添加</div>
					<div class="cz" @click="add_cz">重置</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			MedicalInfo: [],
			DiagnosisArr: [],
			PathogenesisArr: [], //病机
			GroupList: [],
			GroupArr: [],
			PrescriptionArr: [],
			JianFuArr: [],
			YaoJiArr: [],
			ChuFang: [],
			Search: '',
			ChuFangList: [],
			UnitList: [],
			YaoJiShow: false,
			YaoJiInfo: {
				name: '',
				nums: 1,
				unit: ''
			}
			// YaoJiPushArr:[],
		};
	},
	computed: {
		...mapState(['userInfo'])
	},
	created() {
		if (!this.$route.params.id) return this.$router.go(-1);
		let Arr = this.$route.params.info;
		if (!Arr) return this.$router.go(-2);
		Arr = JSON.parse(Arr);
		this.ChuFang = Arr;
		// console.log(Arr);
		// console.log(Arr);
		// let ChuFangList = [];
		// for (let x = 0; x < Arr.length; x++) {
		//   for (let y = 0; y < Arr[x].fangji_json.length; y++) {
		//     ChuFangList.push(Arr[x].fangji_json[y]);
		//   }
		// }
		// this.ChuFangList = ChuFangList;
		// console.log(ChuFangList, "ChuFangList");
		this.GetMedicalInfo();
		this.GetMedicalType();
		this.GetPathogenesis();
		this.GetPrescription();
		this.GetDropdown();
	},
	methods: {
		// 自组添加
		AddYaoJi() {
			this.add_cz();
			this.YaoJiShow = true;
		},
		// 上一步
		GetPrevious() {
			this.$router.push({
				name: 'Group',
				params: { id: this.$route.params.id }
			});
		},
		// 下一步
		async GetNext() {
			let ChuFangList = this.ChuFangList;
			let JianFuArr = this.JianFuArr;
			let jianfu_json = [];
			let fangji_json = [];
			for (let x = 0; x < ChuFangList.length; x++) {
				let name = ChuFangList[x].name + ChuFangList[x].nums + ChuFangList[x].unit;
				fangji_json.push(name);
			}
			if (fangji_json.length == 0) return this.ElmMsg('请选择方剂!');
			// fangji_json = fangji_json.join(",");
			fangji_json = JSON.stringify(fangji_json);
			for (let x = 0; x < JianFuArr.length; x++) {
				let name = JianFuArr[x].value;
				jianfu_json.push(name);
			}
			// jianfu_json = jianfu_json.join(",");
			jianfu_json = JSON.stringify(jianfu_json);
			let { data } = await this.$axios.post(
				'/api/yian/set_fangji',
				{
					yian_id: this.$route.params.id,
					comment: this.Search,
					jianfu_json,
					fangji_json
				},
				{
					headers: {
						token: JSON.parse(localStorage.getItem('userinfo')).token
					}
				}
			);
			if (data.code == 401) return this.CleanLogin();
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			this.$router.push({
				name: 'Export',
				params: { id: this.$route.params.id }
			});
		},
		// 重置
		Reset() {},
		async GetMedicalInfo() {
			let { data } = await this.$axios.get('/api/yian/get_basic?id=' + this.$route.params.id, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) {
				return this.CleanLogin();
			}
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			this.MedicalInfo = data.data;
		},
		async GetMedicalType() {
			let { data } = await this.$axios.get('/api/yian/get_bingzheng?id=' + this.$route.params.id, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) {
				return this.CleanLogin();
			}
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			if (data.code == 1) {
				this.DiagnosisArr = data.data;
			}
		},
		// 查看病机
		async GetPathogenesis() {
			let { data } = await this.$axios('/api/yian/get_bingji?id=' + this.$route.params.id, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) {
				return this.CleanLogin();
			}
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			this.PathogenesisArr = data.data.list;
		},
		// 查看方证
		async GetPrescription() {
			let { data } = await this.$axios.get('/api/yian/get_fangzheng?id=' + this.$route.params.id, {
				headers: {
					token: JSON.parse(localStorage.getItem('userinfo')).token
				}
			});
			if (data.code == 401) {
				return this.CleanLogin();
			}
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			//   转json
			let Prescription = data.data;
			let arr = [];
			for (let x = 0; x < Prescription.length; x++) {
				Prescription[x].fangji_json = JSON.parse(Prescription[x].fangji_json);
				arr.push(Prescription[x].fangji_json);
				Prescription[x].jianfu_json = JSON.parse(Prescription[x].jianfu_json);
				Prescription[x].jinji_json = JSON.parse(Prescription[x].jinji_json);
				Prescription[x].leifang_json = JSON.parse(Prescription[x].leifang_json);
				for (let y = 0; y < Prescription[x].fangji_json.length; y++) {
					this.YaoJiArr.push(Prescription[x].fangji_json[y]);
				}
				for (let y = 0; y < Prescription[x].jianfu_json.length; y++) {
					this.JianFuArr.push(Prescription[x].jianfu_json[y]);
				}
			}
			this.GroupArr = Prescription; //组方
			this.PrescriptionArr = Prescription; // 方剂
			let ChuFangList = [];
			for (let x = 0; x < arr.length; x++) {
				for (let y = 0; y < arr[x].length; y++) {
					let obj = {};
					obj.nums = Number(arr[x][y].num);
					obj.name = arr[x][y].name;
					obj.unit = arr[x][y].unit;
					ChuFangList.push(obj);
				}
			}
			this.ChuFangList = ChuFangList;
		},
		async GetDropdown() {
			let { data } = await this.$axios.get('/api/common/info');
			if (data.code == 401) {
				return this.CleanLogin();
			}
			if (data.code != 1) {
				return this.ElmMsg(data.msg);
			}
			this.UnitList = data.data.unit;
		},
		// 监听输入框变化
		handleChange(value) {
			console.log(value);
		},
		// 单位切换
		handleCommand(command) {
			// let item = command;
			// let ChuFangList = this.ChuFangList;
			// let Num;
			//  换算出下标
			// for (let x = 0; x < ChuFangList.length; x++) {
			//   if (ChuFangList[x].unit == item.name) {
			//     Num = x;
			//   }
			// }
			// console.log(Num);
			//   ChuFangList[Num].unit = item.name;
			//   console.log(ChuFangList, "ChuFangList");
		},
		handleIndex(index, items) {
			// console.log(index);
			let ChuFangList = this.ChuFangList;
			ChuFangList[index].unit = items.name;
		},
		// 自主添加
		// 重置
		add_cz() {
			let YaoJiInfo = {
				name: '',
				nums: '',
				unit: ''
			};
			this.YaoJiInfo = YaoJiInfo;
		},
		// 选择单位
		YaoJiCommand(command) {
			this.YaoJiInfo.unit = command.name;
		},
		// 确定添加
		add_push() {
			if (!this.YaoJiInfo.name) return this.ElmMsg('请填写药剂名称!');
			// 判断是否重名
			for (let x = 0; x < this.ChuFangList.length; x++) {
				if (this.ChuFangList[x].name == this.YaoJiInfo.name) {
					return this.ElmMsg('已有当前药剂名称!');
				}
			}
			if (!this.YaoJiInfo.nums) return this.ElmMsg('请填写剂量!');
			if (!this.YaoJiInfo.unit) return this.ElmMsg('请填写药剂单位!');
			let YaoJiInfo = this.YaoJiInfo;
			YaoJiInfo.nums = Number(YaoJiInfo.nums);
			// console.log(YaoJiInfo);
			this.ChuFangList.push(YaoJiInfo);
			// console.log(this.ChuFangList);
			this.YaoJiShow = false;
		},
		// 删除处方
		async DeleteChuFang(item, index) {
			let result = await this.ElmOpen('是否要删除该药剂!', '确定');
			console.log(result);
			if (result == 'confirm') {
				// 删除
				let ChuFangList = this.ChuFangList;
				ChuFangList.splice(index, 1);
				this.ChuFangList = ChuFangList;
				this.ElmMsg('删除成功!');
			}
		}
	}
};
</script>
<style lang="less" scoped>
.zt_active {
	color: #fff !important;
	background: #f75444 !important;
}
.zt_active1 {
	color: red !important;
}
.add_yaoji {
	width: 360px;
	height: 320px;
	margin: 0 auto;
	.yao_name {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.cz {
			border: 1px solid #ddd;
			color: #444;
			background: #f4f4f4;
			width: 88px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			letter-spacing: 2px;
			margin-left: 30px;
			cursor: pointer;
		}
		.xyb {
			background: #328ffe;
			color: #ffffff;
			width: 88px;
			height: 36px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			letter-spacing: 2px;
			margin-left: 30px;
			cursor: pointer;
		}
		.name {
			width: 80px;
		}
		.inputs {
			width: 180px;
		}
		.danwei {
			width: 180px;
			height: 36px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			border-radius: 4px;
			border: 1px solid #dcdfe6;
		}
	}
}
.Lndividual {
	width: 100%;
	.Lndividual_from {
		width: 100%;
		background: #ffffff;
		padding: 30px;
		box-sizing: border-box;
		// padding-bottom:0 ;
		// height: calc(100vh - 100px);
		.MedicalInfo {
			height: 38px;
		}
		.Medical {
			display: flex;
			div {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #f4f4f4;
				box-sizing: border-box;
				letter-spacing: 2px;
				height: 100%;
			}
			.name {
				background: #f5f5f5;
				color: #333;
				width: 140px;
				font-weight: 600;
			}
			.txt {
				width: 150px;
			}
		}
		.MedicalRemarks {
			height: 66px;
			.input {
				width: calc(100% - 140px);
			}
		}
		.Diagnosis_YiZhu {
			height: 40px !important;
			.YiZhu_input {
				width: 100%;
				height: 100%;
			}
		}
		.Diagnosis_ChuFang {
			min-height: 130px;
			height: calc(100vh - 700px) !important;
			background: #fafafa;
			.ChuFang_YaoJi {
				height: 55%;
				display: flex;
				justify-content: space-between;
				.ChuFang_YaoJi_list {
					width: calc(100% - 125px);
					display: flex;
					flex-wrap: wrap;
					overflow: auto;
					&::-webkit-scrollbar {
						display: none;
					}
					.ChuFang_YaoJi_item {
						display: flex;
						align-items: center;
						margin-top: 14px;
						width: 400px;
						.ChuFang_YaoJi_item_name {
							font-size: 14px;
							font-weight: 600;
							margin-right: 10px;
							width: 70px;
						}
						.ChuFang_YaoJi_item_num {
							.el-input-number {
								width: 130px !important;
							}
						}
						.ChuFang_YaoJi_item_dropdown {
							width: 60px;
							height: 36px;
							.xianshi {
								width: 100%;
								height: 100%;
								border: 1px solid #dcdfe6;
								margin-left: 14px;
								display: flex;
								justify-content: center;
								align-items: center;
								// background: #409eff;
								// color: #fff;
								color: #333;
								border-radius: 4px;
								i {
									margin-left: 4px;
								}
							}
							/deep/ .el-dropdown {
								width: 60px !important;
								height: 100%;
							}
						}
						.DeleteChuFang {
							width: 60px;
							height: 38px;
							border-radius: 4px;
							background: #f75444;
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-left: 30px;
							cursor: pointer;
						}
					}
				}
				.ChuFang_YaoJi_add {
					width: 100px;
					display: flex;
					justify-content: flex-end;
					flex-direction: column;
					align-items: center;
					cursor: pointer;
					div {
						background: #409eff;
						color: #fff;
						padding: 6px 25px;
						height: 25px;
						width: 50px;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			.ChuFang_JianFu {
				width: 100%;
				height: 35%;
				display: flex;
				align-items: center;
				overflow-y: auto;
				&::-webkit-scrollbar {
					display: none;
				}
				.ChuFang_JianFu_title {
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
				}
				.ChuFang_JianFu_list {
					.ChuFang_JianFu_item {
						line-height: 16px;
						font-size: 12px;
					}
				}
			}
			.ChuFang_Nub {
				font-size: 14px;
				font-weight: 600;
			}
		}
		.Diagnosis {
			height: 80px;
			.DiagnosisInfo {
				width: calc(100% - 140px);
				overflow: auto;
				height: 100%;
				justify-content: flex-start;
				padding: 0 20px;
				box-sizing: border-box;
				flex-wrap: wrap;
				border-top: 1px solid #dcdfe6;
				.DiagnosisItems {
					width: 100%;
					margin-top: 10px;
					.DiagnosisItems_title {
						width: 100%;
						display: flex;
						font-size: 14px;
						font-weight: 100 !important;
					}
					.DiagnosisItems_zufang {
						display: flex;
						flex-wrap: wrap;
						width: 100%;
						height: 32px;
						div {
							margin-right: 10px;
						}
					}
					.DiagnosisItems_zufang_title {
						font-size: 14px;
						font-weight: 600;
						margin-top: 4px;
						margin-bottom: 4px;
					}
				}
				.DiagnosisItem {
					padding: 5px 12px;
					box-sizing: border-box;
					//   background: #409eff;
					color: #ffffff;
					height: 30px;
					border-radius: 4px;
					margin-right: 8px;
					position: relative;
					i {
						position: absolute;
						top: -5px;
						right: -5px;
						background: red;
						border-radius: 50%;
						width: 15px;
						height: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						padding-left: 2px;
						box-sizing: border-box;
						font-size: 12px;
					}
				}
			}
		}
		.Medicals {
			display: flex;
			.div {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #f4f4f4;
				box-sizing: border-box;
				letter-spacing: 2px;
				height: 100%;
			}
			.name {
				background: #f5f5f5;
				color: #333;
				width: 140px;
				font-weight: 600;
			}
			.txt {
				width: 150px;
			}
		}
		.Physician {
			height: 36px;
			display: flex;
			justify-content: flex-end;
			border-left: 1px solid #f4f4f4;
			border-bottom: 1px solid #f4f4f4;
			border-top: 1px solid #f4f4f4;
			.PhysicianInfo {
				display: flex;
				height: 100%;
				div {
					border-bottom: none !important;
					border-top: none !important;
				}
			}
		}
		.operation {
			width: 100% !important;
		}
		.operation_top {
			margin-top: 30px;
		}
	}
}
.tuijian_list {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.Search_listss {
	width: 100%;
	border: 1px solid #dcdfe6;
	margin-top: 30px;
	height: calc(100vh - 680px);
	overflow: auto;
	.Search_list_title {
		height: 36px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		box-sizing: border-box;
		background: #f9f9f9;
		color: #999999;
	}
	.Search_item {
		width: 100%;
		display: flex;
		border-bottom: 1px solid #dcdfe6;
		.zufang_index {
			width: 10%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
		}
		.zufang_title {
			display: flex;
			width: 15%;
			align-items: center;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
			padding: 20px 30px;
			box-sizing: border-box;
		}
		.zufang_info {
			width: 65%;
			padding: 15px;
			box-sizing: border-box;
			border-top: 0 !important;
			border-left: 1px solid #dcdfe6;
			.zufang_fangfa_title {
				font-size: 14px;
				font-weight: 600;
				margin-top: 4px;
				margin-bottom: 4px;
			}
		}
		.zufang_msg {
			width: 10%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #dcdfe6;
		}
	}
}
</style>

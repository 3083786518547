<template>
	<div class="editIp">
		<div class="ipbox">
			<div class="ipbox_title">编辑ip地址</div>
			<div class="ipbox_textarea">
				<el-input type="textarea" placeholder="一行为一个ip" v-model="ipData.allow_ip" :autosize="{ minRows:6}"></el-input>
			</div>
			<div class="ipbox_flex">
				<div class="ipbox_flex_left">是否允许登录</div>
				<div class="ipbox_flex_right">
					<el-switch v-model="ipData.allow_login" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
				</div>
			</div>
			<div class="ipbox_flex">
				<div class="ipbox_flex_left">登录时段</div>
				<div class="ipbox_flex_right">
					<el-time-picker is-range v-model="ipData.allow_time" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" format='HH:mm'></el-time-picker>
				</div>
			</div>
			<div class="confim">
				<el-button type="primary" @click="editIpConfim">提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ipData: {}
			};
		},
		created() {
			this.getIP();
		},
		methods: {
			// 编辑用户
			async editIpConfim(){
				let date = this.ipData.allow_time;
				let DateStart = this.formatDate(new Date(date[0]).getTime() / 1000).split(" ")[1];
				let DateEnd = this.formatDate(new Date(date[1]).getTime() / 1000).split(" ")[1];
				let start = DateStart.slice(0,DateStart.lastIndexOf(":"))
				let end = DateEnd.slice(0,DateEnd.lastIndexOf(":"))
				let { data } = await this.$axios.post(
				  "/api/user/update_field",
				  {
					  allow_ip: this.ipData.allow_ip,
					  allow_time: `${start}-${end}`,
					  allow_login: this.ipData.allow_login
				  },{
					  headers: {
						  token: JSON.parse(localStorage.getItem("userinfo")).token,
					  },
					}
				);
				if (data.code == 401)	return this.CleanLogin();
				if (data.code != 1) {
					return this.ElmMsg(data.msg);
				}
				this.$message({
					message: data.msg,
					type: 'success',
					duration: 1500
				});
				this.getIP();
			},
			// 获取用户列表
			async getIP(type){
				let { data } = await this.$axios.get(
				  `/api/user/get_field`,{
					  headers: {
						  token: JSON.parse(localStorage.getItem("userinfo")).token,
					  },
					}
				);
				if (data.code == 401)	return this.CleanLogin();
				if (data.code != 1) {
					return this.ElmMsg(data.msg);
				}
				let info = data.data
				if(info.allow_time){
					let start = info.allow_time.split("-")[0]
					let end = info.allow_time.split("-")[1]
					info.allow_time = [new Date(2016,9,10,parseInt(start.split(":")[0]),parseInt(start.split(":")[1])),new Date(2016,9,10,parseInt(end.split(":")[0]),parseInt(end.split(":")[1]))]
				}
				this.ipData = info
			}
		}
	}
</script>

<style lang="less" scoped>
	.editIp{
		width: 100%;
		height: calc(100vh - 100px);
		background-color: #fff;
	}
	.ipbox{
		// width: 300px;
		padding: 30px;
		.ipbox_title{
			font-size: 16px;
			margin-bottom: 20px;
			color: #000;
			font-weight: bold;
		}
		.confim{
			text-align: center;
			margin-top: 20px;
		}
		.ipbox_textarea{
			width: 300px;
		}
	}
	.ipbox_flex{
		display: flex;
		align-items: center;
		margin-top: 15px;
		.ipbox_flex_left{
			font-size: 16px;
		}
		.ipbox_flex_right{
			margin-left: 20px;
		}
	}
</style>
<template>
  <div class="Lndividual">
    <div class="Lndividual_from">
      <div class="Lndividual_from_box">
        <div class="Lndividual_from_box_left">
          <!-- 信息 -->
          <div class="MedicalInfo Medical">
            <div class="name">姓名</div>
            <div class="txt">{{ MedicalInfo.name }}</div>
            <div class="name">年龄</div>
            <div class="txt">{{ MedicalInfo.age }}</div>
            <div class="name">性别</div>
            <div class="txt">{{ MedicalInfo.gender_text }}</div>
            <div class="name">出生日期</div>
            <div class="txt">{{ MedicalInfo.birth }}</div>
          </div>
          <div class="Lndividual_from_scroll">
            <!-- 备注 -->
            <div class="MedicalRemarks Medical beizhu">
              <div class="name">备注</div>
              <div class="input">{{ MedicalInfo.summary }}</div>
            </div>
            <!-- 四诊信息 -->
            <div class="Diagnosis Medical sizhen">
              <div class="name">四诊信息</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItem"
                  v-for="(item, index) in DiagnosisArr"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <!-- 病机 -->
            <div class="Diagnosis Medicals bingji">
              <div class="name div">病机</div>
              <!-- <div class="DiagnosisInfo">
								<div class="DiagnosisItems" v-for="(item, index) in PathogenesisArr" :key="index">
									<div class="DiagnosisItems_title">方证{{ index + 1 }}：{{ item.name }}</div>
								</div>
							</div> -->
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItems"
                  v-for="(item, index) in PathogenesisArr"
                  :key="index"
                >
                  <div class="DiagnosisItems_title">
                    {{ item.name }} ( {{ item.percent }})
                  </div>
                  <div class="DiagnosisItems_info">
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">六经:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.liujing }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">脏腑经络:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zangfu }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">八纲六邪:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.bagang }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">治则:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zhize }};
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Diagnosis Medicals zufang">
              <div class="name div">组方</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItems"
                  v-for="(item, index) in GroupArr"
                  :key="index"
                >
                  <div class="DiagnosisItems_title">
                    组方{{ index + 1 }}：{{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 医师 -->
          <div class="Physician Medical">
            <div class="PhysicianInfo">
              <div class="name">医师:</div>
              <div class="txt">{{ userInfo.nickname }}</div>
            </div>
          </div>
        </div>
        <!-- 操作 -->
        <div class="operation operation_row operation_top">
          <div class="syb" @click="GetPrevious">上一步</div>
          <div class="cz" @click="Reset">重置</div>
          <div class="xyb" @click="GetNext">下一步</div>
        </div>
      </div>
      <!-- 组方列表 -->
      <div class="tuijian_list">
        <div class="Search_listss">
          <div class="Search_list_title">组方列表:</div>
          <div class="Search_item_scroll">
            <div>
              <div
                class="Search_item"
                v-for="(item, index) in GroupList"
                :key="index"
              >
                <div class="zufang_index">组方{{ index + 1 }}</div>
                <div class="zufang_title">
                  <el-checkbox
                    v-model="item.choice"
                    @change="ChoiceGroup(item, index)"
                    >{{ item.name }}</el-checkbox
                  >
                </div>
                <div class="zufang_info">
                  <div class="zufang_info_flex">
                    <div class="zufang_info_flex_name">方剂组成：</div>
                    <div
                      v-for="(items, indexs) in item.fangji_json"
                      :key="indexs"
                      class="zufang_info_flex_txt"
                    >
                      {{ items.name }}
                    </div>
                  </div>
                  <div class="zufang_info_flex">
                    <div class="zufang_info_flex_name">禁忌：</div>
                    <div
                      class="zufang_info_flex_box"
                      style="flex-direction: column;"
                    >
                      <div
                        v-for="(items, indexs) in item.jinji_json"
                        :key="indexs"
                        class="zufang_info_flex_txt"
                      >
                        {{ items.value }}
                      </div>
                    </div>
                  </div>
                  <div class="zufang_info_flex">
                    <div class="zufang_info_flex_name">类方鉴别：</div>
                    <div
                      class="zufang_info_flex_box"
                      style="flex-direction: column;"
                    >
                      <div
                        v-for="(items, indexs) in item.leifang_json"
                        :key="indexs"
                        class="zufang_info_flex_txt"
                      >
                        {{ items.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <!-- <div class="Pathogenesis_page" v-if="GroupList.length != 0">
						<el-pagination
							background
							layout="prev, pager, next"
							:total="GroupList_total"
							@current-change="GroupListlistpage"
							@prev-click="GroupListlastpage"
							@next-click="GroupListnextpage"
						></el-pagination>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      MedicalInfo: [],
      DiagnosisArr: [],
      PathogenesisArr: [], //病机
      GroupList: [],
      GroupArr: [],
      GroupList_total: null,
      GroupList_page: 1,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (!this.$route.params.id) return this.$router.go(-1);
    this.GetMedicalInfo();
    this.GetMedicalType();
    this.GetPathogenesis();
    //
    if (this.$route.params.type == 2) {
      this.FindFangZheng();
    } else {
      this.GetGroup();
    }
    this.$nextTick(() => {
      setTimeout(() => {
        let msg = document.getElementsByClassName("Lndividual_from_scroll")[0];
        let scrollHei = document.getElementsByClassName("beizhu")[0];
        let scrollHei2 = document.getElementsByClassName("sizhen")[0];
        msg.scrollTop = scrollHei.scrollHeight + scrollHei2.scrollHeight;
      }, 200);
    });
  },
  methods: {
    // 上一步
    GetPrevious() {
      this.$router.push({
        name: "Pathogenesis",
        params: { id: this.$route.params.id, type: 2 },
      });
    },
    // 下一步
    async GetNext() {
      if (this.GroupArr.length == 0) return this.ElmMsg("请选择组方!", 1);
      // 跳转携带数据
      let arr = [];
      for (let x = 0; x < this.GroupArr.length; x++) {
        arr.push(this.GroupArr[x].info.id);
      }
      arr = arr.join(",");
      let { data } = await this.$axios.post(
        "/api/yian/set_fangzheng",
        {
          yian_id: this.$route.params.id,
          bingji_id: arr,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$router.push({
        name: "Prescription",
        params: { id: this.$route.params.id },
      });
      //   info: JSON.stringify(this.GroupArr)
    },
    // 重置
    Reset() {
      this.GroupArr = [];
      this.GroupList = [];
      this.GetGroup();
    },
    async GetMedicalInfo() {
      let { data } = await this.$axios.get(
        "/api/yian/get_basic?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.MedicalInfo = data.data;
    },
    async GetMedicalType() {
      let { data } = await this.$axios.get(
        "/api/yian/get_bingzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.code == 1) {
        this.DiagnosisArr = data.data;
      }
    },
    // 查看病机
    async GetPathogenesis() {
      let { data } = await this.$axios(
        "/api/yian/get_bingji?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.PathogenesisArr = data.data.list;
    },
    // 查看方证
    async FindFangZheng() {
      let { data } = await this.$axios.get(
        "/api/yian/get_fangzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let GroupArr = [];
      for (let x = 0; x < data.data.length; x++) {
        let obj = {};
        obj.name = data.data[x].name;
        obj.info = {};
        obj.info.id = data.data[x].pat_id;
        GroupArr.push(obj);
      }
      this.GroupArr = GroupArr;

      this.GetGroup(true);
      // 组方列表选中
    },
    // 组方列表
    async GetGroup(type = false) {
      let { data } = await this.$axios.get(
        "/api/yian/fangzheng_list?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      //   转json
      let GroupList = data.data;
      for (let x = 0; x < GroupList.length; x++) {
        GroupList[x].fangji_json = JSON.parse(GroupList[x].fangji_json);
        GroupList[x].jinji_json = JSON.parse(GroupList[x].jinji_json);
        GroupList[x].leifang_json = JSON.parse(GroupList[x].leifang_json);
        GroupList[x].jianfu_json = JSON.parse(GroupList[x].jianfu_json);
        if (type) {
          let GroupArr = this.GroupArr;
          console.log();
          if (
            GroupArr.findIndex((item) => item.name == GroupList[x].name) > -1
          ) {
            GroupList[x].choice = true;
          }
          // for (let y = 0; y < GroupArr.length; y++) {
          // 	if (GroupArr[y].name == GroupList[x].name) {
          // 		console.log('111');
          // 		GroupList[x].choice = true;
          // 	} else {
          // 		GroupList[x].choice = false;
          // 	}
          // }
        } else {
          GroupList[x].choice = false;
        }
      }
      this.GroupList = GroupList;
    },
    // 选择组方
    ChoiceGroup(item, index) {
      console.log(item);
      if (item.choice) {
        this.GroupList.choice = true;
      } else {
        this.GroupList.choice = false;
      }
      let obj = {};
      obj.name = item.name;
      obj.choice = true;
      obj.index = index;
      obj.info = item;
      let type = item.choice ? 1 : 2;
      this.CountGroupInfo(obj, type);
    },
    CountGroupInfo(obj, type) {
      if (type == 1) {
        this.PushGroup(obj);
      } else {
        this.DeleteGroup(obj);
      }
    },
    // 加入组方
    PushGroup(obj) {
      let GroupArr = this.GroupArr;
      if (GroupArr.length == 0) {
        return GroupArr.push(obj);
      }
      for (let x = 0; x < GroupArr.length; x++) {
        if (GroupArr[x].name != obj.name) {
          return GroupArr.push(obj);
        }
      }
      this.GroupArr = GroupArr;
    },
    // 取消组方
    DeleteGroup(obj) {
      let GroupArr = this.GroupArr;
      let Num;
      for (let x = 0; x < GroupArr.length; x++) {
        if (GroupArr[x].name == obj.name) {
          Num = x;
        }
      }
      GroupArr.splice(Num, 1);
      this.GroupArr = GroupArr;
    },
    // 分页
  },
};
</script>
<style lang="less">
.zt_active {
  color: #fff !important;
  background: #f75444 !important;
}
.zt_active1 {
  color: red !important;
}
.zufang {
  .name {
    min-height: 30px;
  }
}
.bingji {
  height: auto !important;
  .name {
    min-height: 30px;
  }
}
.Lndividual {
  width: 100%;
  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 0;
    box-sizing: border-box;
    // padding-bottom:0 ;
    // height: calc(100vh - 100px);
    .Lndividual_from_box {
      height: 35%;
      .Lndividual_from_scroll {
        height: calc(100% - 38px - 38px);
        overflow-y: auto;
      }
    }
    .MedicalInfo {
      height: 38px;
    }
    .Medical {
      display: flex;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dcdfe6;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
        border: none;
      }
      .txt {
        width: 150px;
      }
    }
    .MedicalRemarks {
      height: 66px;
      .input {
        width: calc(100% - 140px);
      }
    }
    .Diagnosis {
      height: 80px;
      .DiagnosisInfo {
        width: calc(100% - 140px);
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        border-top: 1px solid #dcdfe6;
        border-right: 1px solid #dcdfe6;
        &::-webkit-scrollbar {
          display: none;
        }
        .DiagnosisItems {
          width: 100%;
          margin-top: 10px;
          .DiagnosisItems_title {
            width: 100%;
            display: flex;
            font-size: 14px;
            font-weight: 100 !important;
          }
        }
        .DiagnosisItem {
          padding: 5px 12px;
          box-sizing: border-box;
          //   background: #409eff;
          color: #ffffff;
          height: 30px;
          border-radius: 4px;
          margin-right: 8px;
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;
            box-sizing: border-box;
            font-size: 12px;
          }
        }
      }
    }
    .Medicals {
      display: flex;
      .div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }
      .txt {
        width: 150px;
      }
    }
    .Physician {
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #f4f4f4;
      .PhysicianInfo {
        display: flex;
        height: 100%;
        div {
          border-bottom: none !important;
          border-top: none !important;
        }
      }
    }
    .operation {
      // width: 100% !important;
    }
    .operation_row {
      display: block;
      width: 10% !important;
      div {
        margin-top: 15px;
      }
    }
    .operation_top {
      margin-top: 30px;
    }
  }
}
.tuijian_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 65%;
}
.Search_listss {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  border-bottom: none;
  // height: calc(100vh - 615px);
  .Search_list_title {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    background: #f9f9f9;
    color: #999999;
    border-left: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
  }
  .Search_item_scroll {
    height: calc(100% - 36px);
    overflow: auto;
    > div {
      border: 1px solid #dcdfe6;
      border-top: none;
    }
  }
  .Search_item {
    width: 100%;
    display: flex;
    // border-bottom: 1px solid #dcdfe6;
    .zufang_index {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #dcdfe6;
      // border-left: 1px solid #dcdfe6;
    }
    .zufang_title {
      display: flex;
      width: 20%;
      align-items: center;
      border-top: 1px solid #dcdfe6;
      border-left: 1px solid #dcdfe6;
      padding: 20px 30px;
      box-sizing: border-box;
    }
    .zufang_info {
      width: 70%;
      padding: 15px;
      box-sizing: border-box;
      border-top: 1px solid #dcdfe6;
      border-left: 1px solid #dcdfe6;
      .zufang_info_flex {
        display: flex;

        margin-top: 8px;
        .zufang_info_flex_name {
          //   font-weight: 600;
          font-size: 14px;
          margin-right: 4px;
          min-width: 70px;
        }
        .zufang_info_flex_box {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
        .zufang_info_flex_txt {
          font-size: 12px;
          margin-right: 10px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>

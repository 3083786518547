<template>
  <div class="userList">
    <!-- 头部 -->
    <div class="head">
      <div class="head_left">
        <div class="head_left_refresh" @click="refresh()">
          <i class="el-icon-refresh"></i>
        </div>
        <div class="head_left_add" @click="addUser(1)">
          <el-button size="medium" type="primary">添加用户</el-button>
        </div>
      </div>
      <!-- <div class="head_right">
				<div class="head_right_search">
					<el-input v-model="nameVal" placeholder="请输入用户名" clearable></el-input>
				</div>
				<div class="head_right_btn">
					<el-button size="medium" type="primary" @click="search">立即搜索</el-button>
				</div>
			</div> -->
    </div>

    <!-- 列表 -->
    <div class="list">
      <el-table
        :data="userList"
        border
        v-loading="tableLoading"
        style="width: 100%"
        height="100%"
      >
        <el-table-column
          prop="username"
          label="用户名"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="nickname"
          label="姓名"
          width="80"
        ></el-table-column>
        <el-table-column label="性别" width="60">
          <template #default="scope">
            <span>{{ scope.row.gender == 0 ? "男" : "女" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          width="120"
        ></el-table-column>
        <el-table-column label="头像" width="70">
          <template #default="scope">
            <img class="avatar" :src="scope.row.avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额" width="80"></el-table-column>
        <el-table-column
          prop="logintime"
          label="登录时间"
          width="150"
        ></el-table-column>
        <el-table-column label="登录时段" width="150">
          <template #default="scope">
            <span
              v-show="scope.row.allow_time_start && scope.row.allow_time_end"
              >{{ scope.row.allow_time_start }}—{{
                scope.row.allow_time_end
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="是否允许登录" width="150">
          <template #default="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="normal"
              inactive-value="hidden"
              @change="
                ($event) => {
                  editStatus($event, scope.row);
                }
              "
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          width="150"
        ></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template #default="scope">
            <!-- <el-button type="primary" size="small" @click="addUser(2,scope.row.id)">查看</el-button> -->
            <el-button
              type="primary"
              size="small"
              @click="addUser(3, scope.row.id)"
              >编辑</el-button
            >
            <!-- <el-button type="primary" size="small" @click="resetPwd(scope.row)">重置密码</el-button> -->
            <el-button type="danger" size="small" @click="delUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="userpagination"
        @prev-click="userpagination"
        @next-click="userpagination"
      >
      </el-pagination>
    </div>

    <!-- 添加弹窗 -->
    <el-dialog
      :title="
        addType == 1
          ? '添加用户'
          : addType == 2
          ? '查看用户'
          : addType == 3
          ? '编辑'
          : ''
      "
      width="30%"
      v-model="addShow"
    >
      <div class="addUserDialog">
        <el-form
          label-position="right"
          label-width="80px"
          :model="addInfo"
          :rules="addUserRules"
          ref="addUser"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              :disabled="addType != 1"
              v-model="addInfo.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="nickname">
            <el-input
              :disabled="addType == 2"
              v-model="addInfo.nickname"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            :prop="addType == 1 ? 'password' : 'password2'"
          >
            <el-input
              :disabled="addType == 2"
              type="password"
              v-model="addInfo.password"
              show-password
              :placeholder="addType == 1 ? '请输入密码' : '密码为空则不做修改'"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              :disabled="addType == 2"
              v-model="addInfo.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              :disabled="addType == 2"
              maxlength="11"
              v-model="addInfo.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="登录时段" prop="mobile">
            <el-time-picker
              :disabled="addType == 2"
              is-range
              v-model="addInfo.allow_time"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              format="HH:mm"
            ></el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="addUserDialog_footer">
        <el-button @click="addUsercancel">{{
          addType == 2 ? "关闭" : "取消"
        }}</el-button>
        <el-button v-show="addType != 2" type="primary" @click="addUserBtn"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 头部搜索框
      nameVal: "",
      tableLoading: false,
      pageNum: 1,
      userList: [],
      lastPage: 1,
      total: 1,
      addShow: false,
      addType: 1,
      addTitle: 1,
      addInfo: {
        username: "",
        nickname: "",
        password: "",
        email: "",
        mobile: "",
        allow_time: "",
      },
      addUserRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        nickname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
          {
            pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
            message: "请输入正确的邮箱",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          ,
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    userpagination(e) {
      let page = e;
      this.pageNum = page;
      this.getUserList();
    },
    // 更改用户状态
    async editStatus(val, item) {
      if (!item.id) return false;
      let { data } = await this.$axios.post(
        "/api/user/edit",
        {
          id: item.id,
          status: val,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.ElmMsg(data.msg, 2);
    },
    // 搜索用户
    searchUser() {},
    // 删除用户
    delUser(item) {
      this.$confirm(`您确定永久删除用户 ${item.username} 吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delUserConfim(item.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确定删除用户
    async delUserConfim(id) {
      let { data } = await this.$axios.post(
        "/api/user/del",
        {
          id: id,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$message({
        message: data.msg,
        type: "success",
        duration: 1500,
      });
      this.pageNum = 1;
      this.getUserList();
    },
    // 刷新
    refresh() {
      this.pageNum = 1;
      this.getUserList("refresh");
    },
    // 添加用户
    addUser(type, id) {
      this.addType = type;
      if (type != 1) {
        this.getuserDetail(id);
        return false;
      }
      this.addInfo = {
        username: "",
        nickname: "",
        password: "",
        email: "",
        mobile: "",
      };
      this.addShow = true;
    },
    // 获取用户详情
    async getuserDetail(id) {
      let { data } = await this.$axios.get(`/api/user/detail?id=${id}`, {
        headers: {
          token: JSON.parse(localStorage.getItem("userinfo")).token,
        },
      });
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let info = data.data;
      if (info.allow_time) {
        let start = info.allow_time.split("-")[0];
        let end = info.allow_time.split("-")[1];
        info.allow_time = [
          new Date(
            2016,
            9,
            10,
            parseInt(start.split(":")[0]),
            parseInt(start.split(":")[1])
          ),
          new Date(
            2016,
            9,
            10,
            parseInt(end.split(":")[0]),
            parseInt(end.split(":")[1])
          ),
        ];
      }
      this.addInfo = info;
      this.addInfo.password = "";
      this.addShow = true;
    },
    // 添加用户弹窗取消
    addUsercancel() {
      this.$refs["addUser"].resetFields();
      this.addShow = false;
    },
    // 添加用户确认
    addUserBtn() {
      this.$refs["addUser"].validate((valid) => {
        if (valid) {
          if (this.addType == 1) {
            this.addUserConfim();
          } else {
            this.editUserConfim();
          }
        }
      });
    },
    // 编辑用户
    async editUserConfim() {
      let date = this.addInfo.allow_time;
      let DateStart = this.formatDate(new Date(date[0]).getTime() / 1000).split(
        " "
      )[1];
      let DateEnd = this.formatDate(new Date(date[1]).getTime() / 1000).split(
        " "
      )[1];
      let start = DateStart.slice(0, DateStart.lastIndexOf(":"));
      let end = DateEnd.slice(0, DateEnd.lastIndexOf(":"));
      let { data } = await this.$axios.post(
        "/api/user/edit",
        {
          id: this.addInfo.id,
          username: this.addInfo.username,
          nickname: this.addInfo.nickname,
          password: this.addInfo.password,
          email: this.addInfo.email,
          mobile: this.addInfo.mobile,
          allow_time: `${start}-${end}`,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$message({
        message: data.msg,
        type: "success",
        duration: 1500,
      });
      this.addUsercancel();
      this.pageNum = 1;
      this.getUserList();
    },
    async addUserConfim() {
      let { data } = await this.$axios.post(
        "/api/user/register",
        {
          username: this.addInfo.username,
          nickname: this.addInfo.nickname,
          password: this.addInfo.password,
          email: this.addInfo.email,
          mobile: this.addInfo.mobile,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$message({
        message: data.msg,
        type: "success",
        duration: 1500,
      });
      this.addUsercancel();
      this.pageNum = 1;
      this.getUserList();
    },
    // 获取用户列表
    async getUserList(type) {
      this.tableLoading = true;
      let { data } = await this.$axios.get(
        `/api/user/list?page=${this.pageNum}`,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      this.tableLoading = false;
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let list = data.data.data;
      if (list.length > 0) {
        list.forEach((item, index) => {
          item.createtime = this.formatDate(item.createtime);
          item.logintime = this.formatDate(item.logintime);
          if (item.allow_time) {
            item.allow_time_start = item.allow_time.split("-")[0];
            item.allow_time_end = item.allow_time.split("-")[1];
          }
        });
      }
      if (type == "refresh") {
        this.$message({
          message: "刷新成功",
          type: "success",
          duration: 1500,
        });
      }
      this.userList = list;
      this.lastPage = data.data.last_page;
      this.total = data.data.total;
    },
  },
};
</script>

<style lang="less" scoped>
.userList {
  padding: 30px;
  background-color: #fff;
}
.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .head_left {
    display: flex;
    align-items: center;
    .head_left_refresh .el-icon-refresh {
      font-size: 20px !important;
      cursor: pointer;
    }
    .head_left_add {
      margin-left: 20px;
    }
  }
  .head_right {
    display: flex;
    align-items: center;
    .head_right_btn {
      margin-left: 20px;
    }
  }
}
.list {
  margin-top: 30px;
  width: 100%;
  height: calc(100vh - 300px);
  .avatar {
    width: 45px;
  }
}
.pagination {
  text-align: center;
  margin-top: 30px;
}
/deep/ .el-dialog__close {
  font-size: 20px;
}
.addUserDialog {
  display: flex;
  justify-content: center;
  .el-input {
    width: 250px;
  }
}
.addUserDialog_footer {
  text-align: center;
}
</style>

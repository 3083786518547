<template>
  <div class="Process">
    <div class="Lndividual_from">
      <div class="Lndividual_tab">
        <div class="name">姓名</div>
        <div class="input">
          <el-input v-model="Info.name" clearable placeholder="请输入姓名"></el-input>
        </div>
      </div>
      <div class="Lndividual_tab">
        <div class="name">出生日期</div>
        <div class="input">
          <el-date-picker
            v-model="Info.birth"
            type="date"
            placeholder="选择出生日期"
            @change="getDate"
			:editable="false"
          >
          </el-date-picker>
        </div>
		<!-- <div style="margin-left: 20px;">日期格式为：<b>年-月-日</b>，年为4位数字，月和日均为2位数字</div> -->
      </div>
      <div class="Lndividual_tab">
        <div class="name">性别</div>
        <div class="input">
          <el-radio v-model="Info.gender" label="男">男</el-radio>
          <el-radio v-model="Info.gender" label="女">女</el-radio>
        </div>
      </div>
      <div class="Lndividual_tab Lndividual_textarea">
        <div class="name">备注</div>
        <div class="input Lndividual_textareas">
          <el-input
            type="textarea"
            placeholder="请输入备注内容"
            v-model="Info.summary"
			:autosize="{ minRows:4}"
          >
          </el-input>
        </div>
      </div>
      <div class="operation">
        <div class="cz" @click="Reset">重置</div>
        <div class="xyb" @click="GetNext">下一步</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Info: {
        name: "",
        birth: "",
        gender: "",
        summary: "",
      },
      Date: "",
    };
  },
  created() {
    let id = this.$route.params.id;
    if (id) {
      this.Info.id = id;
      this.GetMedicalInfo();
    }
  },
  methods: {
    // 查看基础信息
    async GetMedicalInfo() {
      let { data } = await this.$axios.get(
        "/api/yian/get_basic?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      console.log(data.data);
      this.Info.birth = data.data.birth;
      this.Info.name = data.data.name;
      this.Info.gender = data.data.gender == "male" ? "男" : "女";
      this.Info.summary = data.data.summary;
      this.Date = data.data.birth;
    },
    // 重置
    Reset() {
      let Info = {
        name: "",
        birth: "",
        gender: "",
        summary: "",
      };
      this.Info = Info;
    },
    // 下一步
    async GetNext() {
      let Info = this.Info;
      let gender = Info.gender;
	  if(!Info.name)	return this.ElmMsg("请输入姓名",1)
	  if(!Info.birth)	return this.ElmMsg("请选择出生日期",1)
	  if(!Info.gender){
		  return this.ElmMsg("请选择性别",1)
	  }
      Info.gender = gender == "男" ? "male" : gender == "女" ? "female" : "";
      Info.birth = this.Date;
      let url;
      if (Info.id) {
        url = "/api/yian/update_basic";
      } else {
        url = "/api/yian/set_basic";
      }
      let { data } = await this.$axios.post(
        url,
        {
          ...Info,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.code == 1) {
        // 成功跳转
        this.$router.push({
          name: "Lndividual",
          params: { id: data.data },
        });
      }
    },
    getDate() {
      let time = this.Info.birth;
	  if(!time){
		  return false
	  }
      let year = time.getFullYear();
      let month =
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1);
      let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
      this.Date = year + "-" + month + "-" + day;
    },
  },
};
</script>
<style lang="less" scoped>
.Process {
  width: 100%;
  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    height: calc(100vh - 100px);
    .Lndividual_textarea {
      height: auto !important;
      .Lndividual_textareas {
        width: 440px !important;
      }
    }
    .Lndividual_tab {
      // width: 500px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      height: 42px;
      .name {
        width: 100px;
        text-align: right;
      }
      .input {
        width: 220px;
        margin-left: 30px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

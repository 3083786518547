import { createStore } from 'vuex'

export default createStore({
    state: {
        userInfo: JSON.parse(localStorage.getItem('userinfo')) || '',
    },
    mutations: {
        // // 
        // 用户
        getUserName(state, name) {
            state.userInfo = name
        }
    },
    actions: {},
    modules: {}
})
<template>
  <div class="body">
    <!-- 系统 name -->
    <div class="Admin_Name">
      {{ AdminName || "后台系统" }}
    </div>
    <!-- 轮播图 -->
    <div class="Banner">
      <el-carousel indicator-position="outside" style="height:100%;">
        <el-carousel-item
          v-for="(item, index) in Banner"
          :key="index"
          style="height:100%;"
        >
          <img :src="baseURL + item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 底部 备案号 -->
    <div class="Tabbar">
      <div class="Tabbar_title">
        主办:广州颐中中医诊所有限公司
      </div>
      <div class="Tabbar_title">
        承办:合肥一贯健康科技有限公司
      </div>
      <!-- <div class="Tabbar_title">备案号: {{ Tabbar.beian || "" }}</div> -->
      <div class="Tabbar_title">
        备案号:
        <span class="Tabbar_text"
          ><a href="https://beian.miit.gov.cn/">皖ICP备2023004608号</a></span
        >
      </div>
    </div>
    <!-- 登录界面 -->
    <div class="user" @keydown.enter="EnterSign" v-if="!Login">
      <h2>中医辨证辅助系统</h2>
      <div class="flex border">
        <div class="div_img">
          <img src="../assets/img/login/user_name.png" alt="" />
        </div>
        <input type="text" placeholder="请输入账号" v-model="user_name.name" />
      </div>
      <div class="flex border">
        <div class="div_img">
          <img src="../assets/img/login/password.png" alt="" />
        </div>
        <input
          type="password"
          placeholder="请输入密码"
          v-model="user_name.password"
        />
      </div>
      <div class="forget">
        <div
          class="bchh"
          title="保持会话"
          @click="ChangeConversation"
          :class="Conversation ? 'bchh_active' : ''"
        ></div>
        <div @click="Login = true" class="psd">忘记密码?</div>
      </div>
      <div class="flex but" @click="getSubmit">登录</div>
      <div class="nolist">
        <div class="">还没有账号?</div>
        <div class="" @click="ServiceShow = true">立即加入</div>
      </div>
    </div>
    <!-- 重置 -->
    <div class="user" @keydown.enter="submit" v-if="Login">
      <div class="user_title">
        <div class="ret" @click="Login = false">
          <i class="el-icon-arrow-left"></i>
          返回
        </div>
        <h2>中医辨证辅助系统</h2>
        <div></div>
      </div>
      <div class="flex flex_radio">
        <el-radio v-model="radio" label="1" border>手机号验证</el-radio>
        <el-radio v-model="radio" label="2" border>邮箱验证</el-radio>
      </div>
      <div class="flex border">
        <div class="div_img">
          <img src="../assets/img/login/user_name.png" alt="" />
        </div>
        <input
          type="text"
          :placeholder="radio == 1 ? '请输入手机号' : '请输入邮箱'"
          v-model="user_name.mobile"
        />
      </div>
      <div class="flex border codes">
        <div class="div_img">
          <img src="../assets/img/login/Code.png" alt="" />
        </div>
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="user_name.code"
        />
        <div class="yzm" @click="SignCode">{{ SignCodeTxt }}</div>
      </div>
      <div class="flex border">
        <div class="div_img">
          <img src="../assets/img/login/password.png" alt="" />
        </div>
        <input
          type="password"
          placeholder="请输入新密码"
          v-model="user_name.password"
        />
      </div>
      <div class="flex but" @click="getReset">重置</div>
      <div class="nolist">
        <div class="">还没有账号?</div>
        <div class="" @click="ServiceShow = true">立即加入</div>
      </div>
    </div>
    <!--  -->
    <el-dialog title="提示" v-model="ServiceShow">
      <div class="dialog_info" v-html="Service"></div>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      user_name: {
        name: "",
        password: "",
        code: "",
        mobile: "",
      },
      Login: false, //登录 还是重置密码
      Conversation: false, //会话
      Service: "",
      ServiceShow: false,
      radio: "1",
      SignCodeTxt: "发送验证码",
      ServiceNum: 120,
      SignCodeType: 1,
      timr: null,
      Banner: [],
      AdminName: "",
      Tabbar: {},
    };
  },
  created() {
    this.PostService();
  },
  methods: {
    ...mapMutations(["getUserName"]),
    // 保存会话
    ChangeConversation() {
      this.Conversation = !this.Conversation;
    },
    // 登录验证
    getSubmit() {
      if (this.user_name.name == "") {
        return this.$message.error("账号不能为空~");
      }
      if (this.user_name.password == "") {
        return this.$message.error("密码不能为空~");
      }
      //   验证通过
      this.PostSign();
    },
    async PostSign() {
      let { data } = await this.$axios.post("/api/user/login", {
        account: this.user_name.name,
        password: this.user_name.password,
      });
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 0) return this.ElmMsg(data.msg);
      // 登录成功
      this.ElmMsg(data.msg, 2);
      // 是否保持会话
      // 缓存
      let userinfo = data.data.userinfo;
      console.log(userinfo);
      userinfo.Conversation = this.Conversation;
      this.getUserName(userinfo);
      localStorage.setItem("userinfo", JSON.stringify(userinfo));
      this.$router.push({ path: "/" });
    },
    // 重置密码
    async getReset() {
      let { data } = await this.$axios.post("/api/user/resetpwd", {
        type: this.radio == 1 ? "mobile" : "email",
        mobile: this.radio == 1 ? this.user_name.mobile : "",
        email: this.radio == 2 ? this.user_name.mobile : "",
        newpassword: this.user_name.password,
        captcha: this.user_name.code,
      });
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 0) return this.ElmMsg(data.msg);
      // 登录成功
      this.ElmMsg(data.msg, 2);
      this.Login = false;
      this.EliminateType();
    },
    // 系统配置
    async PostService() {
      let { data } = await this.$axios.post("/api/common/info", {});
      if (data.code == 401) return this.CleanLogin();
      this.Service = data.data.service;
      this.Banner = data.data.banner;
      this.AdminName = data.data.name;
      let Tabbar = {};
      Tabbar.beian = data.data.beian;

      this.Tabbar = Tabbar;
    },
    // 发送验证码
    SignCode() {
      // 1 可以发送验证码
      if (this.SignCodeType == 1) {
        this.PostCode();
      }
    },
    async PostCode() {
      if (!this.user_name.mobile) return this.ElmMsg("请填写手机号");
      // let mobile
      let url;
      let datas = {};
      if (this.radio == 1) {
        // mobile=/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
        url = "/api/sms/send";
        datas.mobile = this.user_name.mobile;
      } else {
        // mobile=/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        url = "/api/ems/send";
        datas.email = this.user_name.mobile;
      }
      // if(!mobile.test(this.user_name.mobile)){
      //   if(this.radio==1){
      //     return this.ElmMsg('请输入正确手机号')
      //   }else{
      //     return this.ElmMsg('请输入正确的邮箱')
      //   }
      // }
      let { data } = await this.$axios.post(url, datas);
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 0) {
        return this.ElmMsg(data.msg);
      }
      // 短信发送成功
      this.ElmMsg(data.msg, 2);
      this.SignCodeType = 2;
      // 开始倒计时
      this.timr = setInterval(() => {
        if (this.ServiceNum <= 1) {
          return this.EliminateType();
        }
        this.ServiceNum--;
        this.SignCodeTxt = this.ServiceNum + "秒";
      }, 1000);
    },
    EliminateType() {
      this.SignCodeType = 1;
      this.SignCodeTxt = "发送验证码";
      this.ServiceNum = 120;
      clearInterval(this.timr);
      4;
      let user_name = {
        name: "",
        password: "",
        code: "",
        mobile: "",
      };
      this.user_name = user_name;
    },
    // 回车 登录
    EnterSign() {
      this.getSubmit();
    },
    // 回车 重置
    EnterEliminate() {
      this.getReset();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-carousel__container {
  height: calc(100vh - 120px - 60px) !important;
}

/deep/ .el-carousel__indicators--outside {
  display: none !important;
}

// 系统 name
.Admin_Name {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 44px;
  font-weight: 600;
  align-items: center;
  letter-spacing: 2px;
}

// 轮播图
.Banner {
  width: 100%;
  height: calc(100vh - 120px - 60px);
}

//
.Tabbar {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .Tabbar_title {
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    letter-spacing: 1px;

    .Tabbar_text /deep/ a {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: #333;
    }
  }
}

// 登录界面
.user {
  position: fixed;
  top: 50%;
  left: 75%;
  transform: translate(-50%) translateY(-50%);
  width: 380px;
  // height: 355px;
  background: #ffffff;
  // box-shadow: 0 3px 6px -1px rgb(0 0 0 / 19%);
  box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 25px 30px;
  z-index: 99;

  h2 {
    color: #333;
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
  }

  .flex {
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    height: 46px;
    cursor: pointer;
    border-radius: 4px;
    justify-content: space-between;

    .div_img {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }
    }

    input {
      height: 100%;
      width: calc(100% - 46px);
      padding-left: 15px;
      font-size: 14px;
      box-sizing: border-box;
    }

    .flex_left {
      display: flex;
      align-items: center;
      width: 185px;
      justify-content: space-between;

      input {
        height: 100%;
        width: 100%;
        padding-left: 15px;
        font-size: 14px;
      }
    }

    .right {
      width: 120px;
    }
  }

  .border {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }

  .codes {
    input {
      width: calc(100% - 140px);
    }

    .yzm {
      width: 90px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid #dcdfe6;
    }
  }

  .but {
    font-size: 16px;
    border-radius: 2px !important;
    background-color: #409eff;
    color: #ffffff;
    height: 48px !important;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    margin-bottom: 15px !important;
    margin-top: 30px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.body {
  background-size: cover;
  // background-image: url("../assets/img/login/login_back.png");
  width: 100vw;
  height: 100vh;
}

.user_title {
  display: flex;
  justify-content: space-between;

  .ret {
    display: flex;
    font-size: 16px;
    cursor: pointer;

    i {
      font-size: 18px;
    }
  }
}

.forget {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  .bchh {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    border: 1px solid #999999;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    &::after {
      content: "保持会话";
      position: absolute;
      right: -90px;
      color: #999999;
      font-size: 14px;
      top: 0px;
      width: 80px;
    }
  }

  .bchh_active {
    border: 1px solid #409eff !important;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 8px;
      height: 8px;
      background: #409eff;
      border-radius: 2px;
    }
  }

  .psd {
    color: #999999;
    font-size: 12px;
    cursor: pointer;
  }

  .sign {
    color: #ff0000;
    font-size: 12px;
    cursor: pointer;
  }
}

.nolist {
  display: flex;
  justify-content: center;

  div:nth-of-type(1) {
    font-size: 12px;
    color: #999999;
    margin-right: 6px;
    font-weight: 600;
  }

  div:nth-of-type(2) {
    font-size: 12px;
    color: #ff0000;
    margin-right: 4px;
    font-weight: 600;
    cursor: pointer;
  }
}

.dialog_info {
  width: 400px;
  height: 360px;
}

.flex_radio {
  justify-content: initial !important;
}
</style>

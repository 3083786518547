<template>
  <div class="Lndividual">
    <div class="Lndividual_from">
      <!-- 信息 -->
      <div class="MedicalInfo Medical">
        <div class="name">姓名</div>
        <div class="txt">
          {{ MedicalInfo.name }}
        </div>
        <div class="name">年龄</div>
        <div class="txt">
          {{ MedicalInfo.age }}
        </div>
        <div class="name">性别</div>
        <div class="txt">
          {{ MedicalInfo.gender_text }}
        </div>
        <div class="name">出生日期</div>
        <div class="txt">
          {{ MedicalInfo.birth }}
        </div>
      </div>
      <!-- 备注 -->
      <div class="MedicalRemarks Medical">
        <div class="name">备注</div>
        <div class="input">
          {{ MedicalInfo.summary }}
        </div>
      </div>
      <!-- 四诊信息 -->
      <div class="Diagnosis Medical">
        <div class="name">四诊信息</div>
        <div class="DiagnosisInfo">
          <div
            class="DiagnosisItem"
            v-for="(item, index) in MedicalInfo.bingzheng"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 病机 -->
      <div class="Diagnosis Medicals">
        <div class="name div">病机</div>
        <div class="DiagnosisInfo">
          <div
            class="DiagnosisItems"
            v-for="(item, index) in MedicalInfo.bingji"
            :key="index"
          >
            <div class="DiagnosisItems_title">
              方证{{ index + 1 }}：{{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="Diagnosis Medicals ">
        <div class="name div">组方</div>
        <div class="DiagnosisInfo">
          <div
            class="DiagnosisItems"
            v-for="(item, index) in MedicalInfo.fangzheng"
            :key="index"
          >
            <div class="DiagnosisItems_title">
              组方{{ index + 1 }}：{{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 处方 -->
      <div class="Diagnosis Medicals Diagnosis_ChuFang">
        <div class="name div">处方</div>
        <div class="DiagnosisInfo">
          <div class="ChuFang_YaoJi">
            <div class="ChuFang_YaoJi_list">
              <div
                class="ChuFang_YaoJi_item"
                v-for="(item, index) in Fangji_json"
                :key="index"
              >
                <div class="ChuFang_YaoJi_item_name">{{ item }}</div>
              </div>
            </div>
          </div>
          <div class="ChuFang_JianFu">
            <div class="ChuFang_JianFu_title">
              煎服法:
            </div>
            <div class="ChuFang_JianFu_list">
              {{ Jianfu_json }}
            </div>
          </div>
          <div class="ChuFang_Nub">共 {{ Fangji_json.length - 1 }} 剂</div>
        </div>
      </div>
      <!-- 医嘱 -->
      <div class="Diagnosis Medicals Diagnosis_YiZhu">
        <div class="name div">医嘱</div>
        <div class="DiagnosisInfo">
          <input
            type="text"
            v-model="Comment"
            placeholder="医生可自主添加相关备注"
            class="YiZhu_input"
            disabled="true"
          />
        </div>
      </div>
      <!-- 医师 -->
      <div class="Physician Medical">
        <div class="PhysicianInfo">
          <div class="name">医师:</div>
          <div class="txt">{{ userInfo.username }}</div>
        </div>
      </div>
      <!-- 操作 -->
      <div class="operation operation_top">
        <!-- <div class="syb" @click="GetPrevious">上一步</div> -->
        <div class="xyb" @click="GetNext">导出医案</div>
        <div class="cz" @click="Reset">返回医案</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      MedicalInfo: [],
      Fangji_json: [],
      Jianfu_json: "",
      Comment: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (!this.$route.params.id) return this.$router.go(-1);
    this.ExportDetails();
  },
  methods: {
    async ExportDetails() {
      let { data } = await this.$axios.get(
        "/api/yian/detail?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
	  if (data.code == 401)	return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let Info = data.data;
      //   for (let x = 0; x < Info.fangzheng.length; x++) {
      //     Info.fangzheng[x].jinji_json = JSON.parse(Info.fangzheng[x].jinji_json);
      //     Info.fangzheng[x].leifang_json = JSON.parse(
      //       Info.fangzheng[x].leifang_json
      //     );
      //     Info.fangzheng[x].fangji_json = JSON.parse(
      //       Info.fangzheng[x].fangji_json
      //     );
      //     Info.fangzheng[x].jianfu_json = JSON.parse(
      //       Info.fangzheng[x].jianfu_json
      //     );
      //   }
      let Fangji_json = Info.fangji.fangji_json;
      let Jianfu_json = Info.fangji.jianfu_json;
      var b = Fangji_json.replace(/&quot;/g, "").replace(/\[|]/g, "");
      var c = Jianfu_json.replace(/&quot;/g, "").replace(/\[|]/g, "");
      this.Fangji_json = b.split(",");
      this.Jianfu_json = c;
      this.MedicalInfo = Info;
      this.Comment = Info.fangji.comment;
    },
    // 下一步
    async GetNext() {
      let { data } = await this.$axios.get(
        "/api/yian/export?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
	  if (data.code == 401)	return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      //  导出
      window.open(this.baseURL + data.data);
    },
    Reset() {
      this.$router.push({
        name: "Case",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.zt_active {
  color: #fff !important;
  background: #f75444 !important;
}
.zt_active1 {
  color: red !important;
}
.add_yaoji {
  width: 360px;
  height: 320px;
  margin: 0 auto;
  .yao_name {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .cz {
      border: 1px solid #ddd;
      color: #444;
      background: #f4f4f4;
      width: 88px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      letter-spacing: 2px;
      margin-left: 30px;
      cursor: pointer;
    }
    .xyb {
      background: #328ffe;
      color: #ffffff;
      width: 88px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      letter-spacing: 2px;
      margin-left: 30px;
      cursor: pointer;
    }
    .name {
      width: 80px;
    }
    .inputs {
      width: 180px;
    }
    .danwei {
      width: 180px;
      height: 36px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
    }
  }
}
.Lndividual {
  width: 100%;
  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 30px;
    box-sizing: border-box;
    // padding-bottom:0 ;
    // height: calc(100vh - 100px);
    .MedicalInfo {
      height: 38px;
    }
    .Medical {
      display: flex;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }
      .txt {
        width: 150px;
      }
    }
    .MedicalRemarks {
      height: 66px;
      .input {
        width: calc(100% - 140px);
      }
    }
    .Diagnosis_YiZhu {
      height: 40px !important;
      .YiZhu_input {
        width: 100%;
        height: 100%;
      }
    }
    .Diagnosis_ChuFang {
      height: calc(100vh - 700px) !important;
      background: #fafafa;
      .ChuFang_YaoJi {
        height: 62%;
        display: flex;
        justify-content: space-between;
        .ChuFang_YaoJi_list {
          width: calc(100% - 125px);
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          .ChuFang_YaoJi_item {
            display: flex;
            align-items: center;
            margin-top: 14px;
            // width: 400px;
            .ChuFang_YaoJi_item_name {
              font-size: 14px;
              font-weight: 600;
              margin-right: 10px;
              width: 70px;
            }
            .ChuFang_YaoJi_item_num {
              .el-input-number {
                width: 130px !important;
              }
            }
            .ChuFang_YaoJi_item_dropdown {
              width: 60px;
              height: 36px;
              .xianshi {
                width: 100%;
                height: 100%;
                border: 1px solid #dcdfe6;
                margin-left: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                // background: #409eff;
                // color: #fff;
                color: #333;
                border-radius: 4px;
                i {
                  margin-left: 4px;
                }
              }
              /deep/ .el-dropdown {
                width: 60px !important;
                height: 100%;
              }
            }
            .DeleteChuFang {
              width: 60px;
              height: 38px;
              border-radius: 4px;
              background: #f75444;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 30px;
              cursor: pointer;
            }
          }
        }
        .ChuFang_YaoJi_add {
          width: 100px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: center;
          div {
            background: #409eff;
            color: #fff;
            padding: 6px 25px;
            height: 25px;
            width: 50px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .ChuFang_JianFu {
        width: 100%;
        height: 30%;
        .ChuFang_JianFu_title {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
        .ChuFang_JianFu_list {
          .ChuFang_JianFu_item {
            line-height: 16px;
            font-size: 12px;
          }
        }
      }
      .ChuFang_Nub {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .Diagnosis {
      height: 80px;
      .DiagnosisInfo {
        width: calc(100% - 140px);
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        border-top: 1px solid #dcdfe6;
        .DiagnosisItems {
          width: 100%;
          margin-top: 10px;
          .DiagnosisItems_title {
            width: 100%;
            display: flex;
            font-size: 14px;
            font-weight: 100 !important;
          }
          .DiagnosisItems_zufang {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 32px;
          }
          .DiagnosisItems_zufang_title {
            font-size: 14px;
            font-weight: 600;
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }
        .DiagnosisItem {
          padding: 5px 12px;
          box-sizing: border-box;
          //   background: #409eff;
          color: #ffffff;
          height: 30px;
          border-radius: 4px;
          margin-right: 8px;
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;
            box-sizing: border-box;
            font-size: 12px;
          }
        }
      }
    }
    .Medicals {
      display: flex;
      .div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }
      .txt {
        width: 150px;
      }
    }
    .Physician {
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-left: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #f4f4f4;
      .PhysicianInfo {
        display: flex;
        height: 100%;
        div {
          border-bottom: none !important;
          border-top: none !important;
        }
      }
    }
    .operation {
      width: 100% !important;
    }
    .operation_top {
      margin-top: 30px;
    }
  }
}
.tuijian_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Search_listss {
  width: 100%;
  border: 1px solid #dcdfe6;
  margin-top: 30px;
  height: calc(100vh - 680px);
  overflow: auto;
  .Search_list_title {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
    background: #f9f9f9;
    color: #999999;
  }
  .Search_item {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #dcdfe6;
    .zufang_index {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
    }
    .zufang_title {
      display: flex;
      width: 15%;
      align-items: center;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
      padding: 20px 30px;
      box-sizing: border-box;
    }
    .zufang_info {
      width: 65%;
      padding: 15px;
      box-sizing: border-box;
      border-top: 0 !important;
      border-left: 1px solid #dcdfe6;
      .zufang_fangfa_title {
        font-size: 14px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
    .zufang_msg {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
</style>

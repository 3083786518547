<template>
  <div class="NavMenu" v-if="Login">
    <div class="NavMenuTop">
      <!-- 顶部操作信息 -->
      <div
        class="top_info"
        :style="{
          width: !isCollapse ? ' calc(100% - 234px)' : ' calc(100% - 64px)',
        }"
      >
        <div class="NavMenuTops">
          <div class="NavMenuTopLeft">
            <!-- 收缩  侧边栏-->
            <div class="NavMenuLeftShow" @click="isCollapse = !isCollapse">
              <!--  -->
              <i class="el-icon-s-fold"></i>
            </div>
            <!-- 页面拼接 -->
            <div class="NavMenuName">
              <div
                v-show="!isSymptom"
                class="NavMenuNamList"
                v-for="(item, index) in NavMenuName"
                :key="index"
                @click="GetLinKTop(item, index)"
              >
                <div class="title">{{ item.title }}</div>
                <span v-if="index != NavMenuName.length - 1">/</span>
              </div>
              <div
                v-show="isSymptom"
                class="NavMenuNamList"
                v-for="(item, index) in NavMenuName2"
                :key="index"
                @click="GetLinKTop(item, index)"
                :class="{ active: NavMenuName2Index == index }"
              >
                <div class="title">{{ item.title }}</div>
                <span v-if="index != NavMenuName2.length - 1">/</span>
              </div>
            </div>
          </div>
          <div class="NavMenuTopRight">
            <!-- 是否全屏 -->
            <!-- <div class="FullScreen">
              <i
                class="iconfont icon-quanping"
                v-if="!FullScreen"
                title="进入全屏"
                @click="GetFullScreen"
              ></i>
              <i
                class="iconfont icon-quanping1"
                v-else
                title="取消全屏"
                @click="GetFullScreen"
              ></i>
            </div> -->
            <!-- 账号信息 -->
            <div class="AdminUser">
              <!-- trigger="hover" -->
              <el-popover placement="top-start" :width="160">
                <template #reference>
                  <div v-if="UserInfo" class="AdminUserImg">
                    <img
                      :src="UserInfo.avatar || '../assets/img/logo.png'"
                      alt=""
                      title="Admin"
                    />
                    <span title="Admin">{{ UserInfo.username }}</span>
                  </div>
                </template>
                <div class="AdminUser_hover">
                  <!-- <div class="AdminUser_link">
                  <div class="" v-for="(item, index) in AdminInfo" :key="index">
                    {{ item.title }}
                  </div>
                </div> -->
                  <div class="AdminUser_name" @click="OpenSign">退出登录</div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
        <!-- 底部跳转 link -->
        <!-- <div class="NavMenuBottom">
          <div class="" v-for="(item, index) in GetFindLink" :key="index"></div>
        </div> -->
      </div>
    </div>
    <!-- 侧边栏 -->
    <div
      class="NavMenuLeft"
      :style="{ width: !isCollapse ? ' 234px' : ' 64px' }"
    >
      <el-menu
        default-active="/"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        background-color="#001529"
        text-color="hsla(0,0%,100%,.65)"
        active-text-color="#ffffff"
        :style="!isCollapse ? 'text-align: inherit;' : 'text-align: center;'"
        router
      >
        <!-- 头部信息 -->
        <div class="head_info">
          <div v-show="!isCollapse">
            <div class="head_title">{{ NavMenuName[0].title }}</div>
            <div v-if="UserInfo" class="head_admin">
              <img :src="UserInfo.avatar || '../assets/img/logo.png'" alt="" />
              <div class="head_admin_data">
                <div class="name">{{ UserInfo.username }}</div>
                <div class="AdminType">
                  <div class="type type1"></div>
                  <div class="typename">在线</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 头部导航 -->
        <template v-for="(item, index) in NavMenu" :key="index">
          <el-menu-item
            v-if="!item.children"
            class=""
            @click="GetLinK(item, index)"
            :class="index == NavMenuIndex ? 'NavMenuIndex' : ''"
          >
            <i :class="item.icon" class="icon" :title="item.title"></i>
            <span>{{ item.title }}</span>
          </el-menu-item>
          <!-- <el-submenu index="" v-else>
						<template #title>
							<i :class="item.icon" class="icon" :title="item.title"></i>
							<span>{{ item.title }}</span>
						</template>
						<el-menu-item v-for="(item2,index2) in item.children" :key="index2" :class="index == NavMenuIndex ? 'NavMenuIndex' : ''" @click="GetLinK(item2, index2)">
							<i :class="item2.icon" class="icon" :title="item.title"></i>
							<span>{{ item2.title }}</span>
						</el-menu-item>
					</el-submenu> -->
        </template>
      </el-menu>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      isCollapse: false,
      NavMenuIndex: 0,
      NavMenu: [
        {
          title: "个人信息",
          icon: "el-icon-user",
          link: "/",
          param: {}, //是否需要携带参数
        },
        {
          title: "使用教程",
          icon: "el-icon-collection",
          link: "/Tutorial",
          param: {},
        },
        {
          title: "辨证流程",
          icon: "el-icon-share",
          link: "/Process",
          param: {},
        },
        {
          title: "医案库管理",
          icon: "el-icon-pie-chart",
          link: "/Case",
          param: {},
        },
        {
          title: "机构管理",
          icon: "el-icon-user",
          link: "/editIp",
          param: {}, //是否需要携带参数
        },
        {
          title: "用户管理",
          icon: "el-icon-user",
          link: "/userList",
          param: {}, //是否需要携带参数
        },
        {
          title: "退出登录",
          icon: "el-icon-setting",
          link: "",
          param: {},
        },
      ],
      NavMenuName: [
        {
          title: "颐中中医辩证辅助系统",
          link: "/",
        },
        {
          title: "",
        },
      ],
      isSymptom: true,
      NavMenuName2: [
        {
          title: "患者信息",
          link: "/",
        },
        {
          title: "症状采集",
          link: "",
        },
        {
          title: "病机辨析",
          link: "",
        },
        {
          title: "方证辨析",
          link: "",
        },
        {
          title: "方剂拟订",
          link: "",
        },
      ],
      NavMenuName2Index: 0,
      isNavMenu: false,
      FullScreen: false,
      AdminInfo: [
        {
          title: "退出登录",
        },
      ],
      AdminArr: [],
      UserInfo: [],
    };
  },
  created() {
    // let Path = window.location.href;
    // Path = Path.split('#')[1];
    // console.log(Path, 'Path');
    // if (Path == '/') {
    // 	console.log('11');
    // 	this.NavMenuIndex = 0;
    // } else if (Path == '/Lndividual') {
    // 	this.NavMenuIndex = 1;
    // 	this.isSymptom = true
    // 	this.NavMenuName2Index = 1
    // } else if (Path == '/Pathogenesis') {
    // 	this.NavMenuIndex = 1;
    // 	this.isSymptom = true
    // 	this.NavMenuName2Index = 2
    // } else if (Path == '/Group') {
    // 	this.NavMenuIndex = 1;
    // 	this.isSymptom = true
    // 	this.NavMenuName2Index = 3
    // } else if (Path == '/Prescription') {
    // 	this.NavMenuIndex = 1;
    // 	this.isSymptom = true
    // 	this.NavMenuName2Index = 4
    // } else if (Path == '/Formula') {
    // 	this.NavMenuIndex = 1;
    // } else if (Path == '/Case') {
    // 	this.NavMenuIndex = 2;
    // } else if (Path == '/editIp') {
    // 	this.NavMenuIndex = 3;
    // } else if (Path == '/userList') {
    // 	this.NavMenuIndex = 4;
    // }
    // this.NavMenuName[1].title = this.NavMenu[this.NavMenuIndex].title;
    this.GetUserInfo();
    this.PostService();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  props: {
    Login: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isCollapse(newName) {
      if (newName) {
        this.$parent.isCollapse = true;
      } else {
        this.$parent.isCollapse = false;
      }
    },
    userInfo: {
      handler(newName) {
        let list = [
          {
            title: "个人信息",
            icon: "el-icon-user",
            link: "/",
            param: {}, //是否需要携带参数
          },
          {
            title: "使用教程",
            icon: "el-icon-collection",
            link: "/",
            param: {},
          },
          {
            title: "辨证流程",
            icon: "el-icon-share",
            link: "/Process",
            param: {},
          },
          {
            title: "医案库管理",
            icon: "el-icon-pie-chart",
            link: "/Case",
            param: {},
          },
          {
            title: "机构管理",
            icon: "el-icon-user",
            link: "/editIp",
            param: {}, //是否需要携带参数
          },
          {
            title: "用户管理",
            icon: "el-icon-user",
            link: "/userList",
            param: {}, //是否需要携带参数
          },
          {
            title: "退出登录",
            icon: "el-icon-setting",
            link: "",
            param: {},
          },
        ];
        console.log(list);
        if (this.userInfo.group_id != 1) {
          list.splice(3, 1);
          list.splice(3, 1);
        }
        this.GetUserInfo();
        this.NavMenu = list;
      },
      immediate: true,
    },
    $route: {
      handler(to, from) {
        if (to.fullPath == "/") {
          this.NavMenuIndex = 0;
          this.isSymptom = false;
        } else if (to.fullPath == "/Lndividual") {
          this.NavMenuIndex = 1;
          this.isSymptom = true;
          this.NavMenuName2Index = 1;
        } else if (to.fullPath == "/Pathogenesis") {
          this.NavMenuIndex = 1;
          this.isSymptom = true;
          this.NavMenuName2Index = 2;
        } else if (to.fullPath == "/Group") {
          this.NavMenuIndex = 1;
          this.isSymptom = true;
          this.NavMenuName2Index = 3;
        } else if (to.fullPath == "/Prescription") {
          this.NavMenuIndex = 1;
          this.isSymptom = true;
          this.NavMenuName2Index = 4;
        } else if (to.fullPath == "/Formula") {
          this.NavMenuIndex = 1;
          this.isSymptom = false;
        } else if (to.fullPath == "/Case") {
          this.NavMenuIndex = 2;
          this.isSymptom = false;
        } else if (to.fullPath == "/editIp") {
          this.NavMenuIndex = 3;
          this.isSymptom = false;
        } else if (to.fullPath == "/userList") {
          this.NavMenuIndex = 4;
          this.isSymptom = false;
        } else if (to.fullPath == "/Process") {
          this.NavMenuIndex = 0;
          this.isSymptom = true;
        }
        this.NavMenuName[1].title = this.NavMenu[this.NavMenuIndex].title;
      },
      immediate: true,
    },
  },
  mounted() {
    window.onresize = () => {
      if (!this.checkFull()) {
        this.FullScreen = false;
      }
    };
  },
  methods: {
    ...mapMutations(["getUserName"]),
    GetLinK(item, index) {
      // 退出登录
      if (!item.link) {
        this.OpenSign();
      } else {
        this.$router.push({ path: item.link });
        this.NavMenuName - item.title;
        this.NavMenuIndex = index;
        // 叠加路径
        this.NavMenuName[1].title = item.title;
        // this.GetFindLink(item);
      }
    },
    GetLinKTop(item, index) {
      if (item.link) {
        this.$router.push({ path: item.link });
        this.NavMenuName[1].title = "";
        this.NavMenuIndex = 0;
        this.NavMenuName[1].title = this.NavMenu[this.NavMenuIndex].title;
      }
    },
    // 叠加链接
    GetFindLink(item) {
      let AdminArr = this.AdminArr;
      let Index = null;
      for (let x = 0; x < AdminArr.length; x++) {
        if (AdminArr[x].title == item.title) {
          Index = x;
        } else {
          AdminArr.push(item);
        }
      }
    },
    // 全屏操作
    GetFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.FullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      // 改变当前全屏状态
      this.FullScreen = !this.FullScreen;
    },
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    // 退出弹框
    async OpenSign() {
      let result = await this.ElmOpen("是否要退出登录, 是否继续?", "退出");
      if (result == "confirm") {
        // 退出登录
        this.LoginOut();
      }
    },
    // 退出登录
    async LoginOut() {
      let { data } = await this.$axios.post(
        "/api/user/logout",
        {},
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.code == 1) {
        // 清除vuex
        this.getUserName({});
        // 清除缓存
        localStorage.removeItem("userinfo");
        this.ElmMsg("退出成功!", 2);
        this.$router.push({ path: "/Login" });
      }
    },
    // 系统配置
    async PostService() {
      let { data } = await this.$axios.post("/api/common/info", {});
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 1) {
        this.NavMenuName[0].title = data.data.name;
      }
    },
    // 个人信息
    GetUserInfo() {
      let UserInfo = JSON.parse(localStorage.getItem("userinfo"));
      // console.log(UserInfo);
      this.UserInfo = UserInfo;
    },
  },
};
</script>
<style lang="less">
.el-menu {
  border-right: none !important;
}
.AdminUser_hover {
  .AdminUser_name_border {
    border-top: 1px solid #e0e0e0;
  }
  .AdminUser_name {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 550;
    cursor: pointer;
  }
  .AdminUser_link {
    div {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .nav_herf div:hover {
    background: #e0e0e0;
  }
}
// .NavMenu {
//   display: flex;
// }
.NavMenu {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 99;
}
.NavMenuTop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .top_info {
    .NavMenuLeftShow {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .el-icon-s-fold {
        font-size: 20px;
        color: #000;
      }
    }
    .NavMenuTopLeft {
      display: flex;
      height: 50px;
      align-items: center;
    }
    .NavMenuTopRight {
      display: flex;
      height: 50px;
      align-items: center;
      margin-right: 20px;
      .FullScreen {
        display: flex;
        height: 50px;
        align-items: center;
        width: 30px;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &:hover i {
          animation: logoAnimation 0.3s ease-in-out;
          display: inline-block;
        }
        @keyframes logoAnimation {
          0% {
            transform: scale(0.6);
            opacity: 0.5;
          }
          50% {
            transform: scale(0.8);
            opacity: 0.7;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
      .AdminUser {
        height: 50px;
        width: 100px;
        margin-left: 15px;
        .AdminUserImg {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
          span {
            color: #000;
            margin-left: 6px;
          }
        }
      }
    }
    .NavMenuName {
      display: flex;
      height: 50px;
      align-items: center;
      .NavMenuNamList {
        display: flex;
        .title {
          font-weight: 400;
          color: #000;
          cursor: pointer;
          &:hover {
            color: #409eff;
          }
        }
        span {
          margin-left: 3px;
          font-weight: 600;
          color: #999999;
          margin-right: 6px;
        }
      }
      .active {
        .title {
          font-weight: bold;
          color: red;
        }
      }
    }
  }
  .NavMenuTops {
    height: 50px;
    display: flex;
    align-items: center;
    background: var(--bg-topBar);
    overflow: hidden;
    border-bottom: 1px solid #f1f2f3;
    justify-content: space-between;
  }
  .NavMenuBottom {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #606266;
    font-size: 12px;
    padding: 0 15px;
    border-bottom: 1px solid #f1f2f3;
  }
}
.NavMenuLeft {
  height: 100vh;
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  background: #001529;
  .head_info {
    width: 100%;
    height: 130px;
    padding: 15px;
    box-sizing: border-box;
    padding-bottom: 0;
    overflow: hidden;
    .head_title {
      font-size: 20px;
      color: #ffffff;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .head_admin {
      width: 100%;
      display: flex;
      padding: 20px 0;
      padding-bottom: 0;
      overflow: hidden;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .head_admin_data {
        width: calc(100% - 60px);
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .name {
          font-size: 16px;
          color: #ffffff;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .AdminType {
          display: flex;
          .type {
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }
          .type1 {
            background: #009e7e;
          }
          .typename {
            margin-left: 10px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
//
.NavMenuIndex {
  background: rgb(0, 17, 33) !important;
}
.NavMenuIndex i {
  color: #409eff !important;
}
.NavMenuIndex span {
  color: #409eff !important;
}
.el-submenu__title {
  color: #fff !important;
  .icon {
    color: #fff !important;
  }
}
// deep
.el-menu-item:hover i,
.el-menu-item:hover span {
  color: #409eff !important;
}
</style>

<template>
  <div class="User">
    <div class="workbench">
      <div class="workbench_title">工作台</div>
      <div class="workbench_user">
        <div class="workbench_user_info">
          <div class="workbench_user_info_avatar">
            <img :src="userData.user.avatar" />
          </div>
          <div class="workbench_user_info_name">
            <div class="workbench_user_info_name_nickname">
              早安，{{ userData.user.nickname }}，祝你开心每一天！
            </div>
            <div class="workbench_user_info_name_group">
              <div class="workbench_user_info_name_identity">
                {{
                  userData.user.group_id == 1
                    ? "机构管理员"
                    : userData.user.group_id == 2
                    ? "机构用户"
                    : userData.user.group_id == 3
                    ? "个人用户"
                    : ""
                }}
              </div>
              <div class="workbench_user_info_name_text">
                <div>电话: {{ userData.user.mobile }}</div>
                <div>邮箱: {{ userData.user.email }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="workbench_user_task">
          <div>
            <div class="workbench_user_task_txt">全部: {{ userData.all }}</div>
            <div class="workbench_user_task_txt">
              未完成: {{ userData.un_done }}
            </div>
            <div class="workbench_user_task_txt">
              已完成: {{ userData.is_done }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Notice">
      <div class="Notice_title">系统公告</div>
      <div class="Notice_list">
        <div
          class="Notice_item"
          v-for="(item, index) in NoticeList"
          :key="index"
          @click="GetNotice(item)"
        >
          <div class="type">[{{ item.category.name }}]</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- 新闻详情弹出层 -->
    <el-dialog title="系统公告" v-model="NoticeShow">
      <div class="dialog_info_title">{{ NoticeDetails.title }}</div>
      <div class="dialog_info" v-html="NoticeDetails.content"></div>
    </el-dialog>
  </div>
  <div class="form">
    <el-form ref="userform" :model="userform"
      ><div class="title">修改个人信息</div>
      <el-form-item label="请输入您的姓名">
        <el-input v-model="userform.nickname"></el-input> </el-form-item
      ><el-form-item label="请输入您的联系方式">
        <el-input
          v-model="userform.phone"
          type="number"
        ></el-input> </el-form-item
      ><el-form-item label="请输入您要更改的的密码">
        <el-input type="password" v-model="userform.password"></el-input>
      </el-form-item>
      <el-row>
        <el-button type="primary">提交</el-button>
        <el-button type="danger">清空</el-button>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      NoticeList: [],
      NoticePage: 1,
      NoticeTotal: null,
      NoticeDetails: "",
      NoticeShow: false,
      userData: {},
      form: {},
      userform: {
        nickname: "",
        phone: "",
        password: "",
      },
    };
  },
  created() {
    this.PostNotice();
    this.getUser();
  },
  methods: {
    // 会员中心
    async getUser() {
      let { data } = await this.$axios.get(`/api/user/index`, {
        headers: {
          token: JSON.parse(localStorage.getItem("userinfo")).token,
        },
      });
      if (data.code == 401) return this.CleanLogin();
      this.userData = data.data;
    },
    async PostNotice() {
      let { data } = await this.$axios.get(
        "/api/dashboard/article?page=" + this.NoticePage,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.NoticeList = data.data.data;
      this.NoticePage = data.data.last_page;
      this.NoticeTotal = data.data.total;
    },
    async GetNotice(item) {
      let { data } = await this.$axios.get(
        "/api/dashboard/articleDetail?id=" + item.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.NoticeShow = true;
      this.NoticeDetails = data.data;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__close {
  font-size: 20px !important;
}
/deep/ .el-dialog__title {
  font-size: 26px !important;
  font-weight: 600;
}
.dialog_info_title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #000 !important;
}
.dialog_info {
  width: 400px;
  height: 360px;
}
.User {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .workbench {
    width: 68%;
    background: #fff;
    .workbench_title {
      font-size: 28px;
      font-weight: 600;
      height: 64px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .workbench_user {
      margin: 30px 20px 0 20px;
      .workbench_user_info {
        display: flex;
        align-items: center;
        .workbench_user_info_avatar img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          overflow: hidden;
        }
        .workbench_user_info_name {
          margin-left: 20px;
          .workbench_user_info_name_nickname {
            font-size: 18px;
          }
          .workbench_user_info_name_group {
            display: flex;
            align-items: center;
            margin-top: 15px;
            height: 30px;
            .workbench_user_info_name_identity {
              font-size: 16px;
              padding-right: 15px;
            }
            .workbench_user_info_name_text {
              padding-left: 15px;
              border-left: 1px solid #000;
              display: flex;
              div {
                margin-right: 15px;
                font-size: 16px;
                margin-bottom: 5px;
              }
              div:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .workbench_user_task {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        > div {
          display: flex;
          justify-content: space-between;
          width: 350px;
          // margin: 20px 0 0 30px;
        }
        .workbench_user_task_txt {
          font-size: 18px;
        }
      }
    }
  }
  .Notice {
    width: 30%;
    background: #fff;
    .Notice_title {
      font-size: 22px;
      height: 64px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dcdfe6;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .Notice_list {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      min-height: 300px;
      .Notice_item {
        padding: 15px 0;
        display: flex;
        cursor: pointer;
        .type {
          font-size: 14px;
        }
        .title {
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }
  }
}

.form {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  .title {
    font-size: 28px;
    color: #1f2d3d;
  }
  form {
    width: 500px !important;
    display: inline-block;
    width: 100%;
    margin: 30px;
  }
}
</style>

<template>
  <div class="Lndividual">
    <div class="Lndividual_from">
      <div class="Lndividual_from_box">
        <div class="Lndividual_from_box_left">
          <!-- 信息 -->
          <div class="MedicalInfo Medical">
            <div class="name">姓名</div>
            <div class="txt">{{ MedicalInfo.name }}</div>
            <div class="name">年龄</div>
            <div class="txt">{{ MedicalInfo.age }}</div>
            <div class="name">性别</div>
            <div class="txt">{{ MedicalInfo.gender_text }}</div>
            <div class="name">出生日期</div>
            <div class="txt">{{ MedicalInfo.birth }}</div>
          </div>
          <div class="Lndividual_from_scroll">
            <!-- 备注 -->
            <div class="MedicalRemarks Medical beizhu">
              <div class="name">备注</div>
              <div class="input">{{ MedicalInfo.summary }}</div>
            </div>
            <!-- 四诊信息 -->
            <div class="Diagnosis Medical sizhen">
              <div class="name">四诊信息</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItem"
                  v-for="(item, index) in DiagnosisArr"
                  :key="index"
                  :class="
                    ChoiceColor(PathogenesisArr, 1, item) ? 'zt_active' : ''
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <!-- 病机 -->
            <div class="Diagnosis Medicals bingji">
              <div class="name div">病机</div>
              <div class="DiagnosisInfo">
                <div
                  class="DiagnosisItems"
                  v-for="(item, index) in PathogenesisArr"
                  :key="index"
                >
                  <div class="DiagnosisItems_title">
                    {{ item.name }} ( {{ item.percent }})
                  </div>
                  <div class="DiagnosisItems_info">
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">六经:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.liujing }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">脏腑经络:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zangfu }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">八纲六邪:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.bagang }};
                      </div>
                    </div>
                    <div class="DiagnosisItems_info_flex">
                      <div class="DiagnosisItems_info_flex_name">治则:</div>
                      <div class="DiagnosisItems_info_flex_txt">
                        {{ item.zhize }};
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 医师 -->
          <div class="Physician Medical">
            <div class="PhysicianInfo">
              <div class="name">医师:</div>
              <div class="txt">{{ userInfo.nickname }}</div>
            </div>
          </div>
        </div>
        <!-- 操作 -->
        <div class="operation operation_row operation_top">
          <div class="syb" @click="GetPrevious">上一步</div>
          <div class="cz" @click="Reset">重置</div>
          <div class="xyb" @click="GetNext">下一步</div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="Lists">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="推荐方证" name="first">
            <el-empty
              description="暂无推荐方证"
              v-if="PathogenesisInfo.length == 0"
            >
              <el-button type="primary" @click="activeName = 'second'"
                >去自定义搜索</el-button
              >
            </el-empty>
            <div class="tuijian_list" v-else>
              <!-- 50% 以上 -->
              <div class="Search_lists">
                <div class="Search_list_title">推荐列表：(按匹配指数排列)</div>
                <div class="Search_item_scroll">
                  <div
                    class="Search_item"
                    v-for="(item, index) in SystemList"
                    :key="index"
                  >
                    <div class="Search_num">{{ item.percent }}</div>
                    <div class="Search_zz">
                      <div class="Search_choice">
                        <el-checkbox
                          v-model="item.choice"
                          @change="ChoiceDiagnosis(item, index)"
                        ></el-checkbox>
                        <div>{{ item.name }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">六经:</div>
                        <div class="Search_data_txt">{{ item.liujing }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">脏腑经络:</div>
                        <div class="Search_data_txt">{{ item.zangfu }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">八纲六邪:</div>
                        <div class="Search_data_txt">{{ item.bagang }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">治则:</div>
                        <div class="Search_data_txt">{{ item.zhize }}</div>
                      </div>
                    </div>
                    <div class="Search_bz">
                      <div class="zz">
                        <div class="name">主症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.zhuzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/&nbsp;</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                      <div class="qz">
                        <div class="name">全症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.quanzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/&nbsp;</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 栅栏 -->
                <!-- <div class="Pathogenesis_fence" v-if="SystemList.length == 1">暂无数据</div> -->
                <!-- 分页 -->
                <div class="Pathogenesis_page" v-if="SystemList.length > 0">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="SystemList_total"
                    @current-change="SystemListlistpage"
                    @prev-click="SystemListlastpage"
                    @next-click="SystemListnextpage"
                    :page-size="SystemList_per_page"
                  ></el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="参考方证" name="third">
            <el-empty
              description="暂无参考方证"
              v-if="PathogenesisInfo.length == 0"
            >
              <el-button type="primary" @click="activeName = 'second'"
                >去自定义搜索</el-button
              >
            </el-empty>
            <div class="tuijian_list" v-else>
              <!-- 50% 以下 -->
              <div class="Search_lists">
                <div class="Search_list_title">参考列表：(按匹配指数排列)</div>
                <div class="Search_item_scroll">
                  <div
                    class="Search_item"
                    v-for="(item, index) in Reference"
                    :key="index"
                  >
                    <div class="Search_num">{{ item.percent }}</div>
                    <div class="Search_zz">
                      <div class="Search_choice">
                        <el-checkbox
                          v-model="item.choice"
                          @change="ChoiceDiagnosis(item, index)"
                        ></el-checkbox>
                        <div>{{ item.name }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">六经:</div>
                        <div class="Search_data_txt">{{ item.liujing }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">脏腑经络:</div>
                        <div class="Search_data_txt">{{ item.zangfu }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">八纲六邪:</div>
                        <div class="Search_data_txt">{{ item.bagang }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">治则:</div>
                        <div class="Search_data_txt">{{ item.zhize }}</div>
                      </div>
                    </div>
                    <div class="Search_bz">
                      <div class="zz">
                        <div class="name">主症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.zhuzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                      <div class="qz">
                        <div class="name">全症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.quanzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 栅栏 -->
                <!-- <div
									class="Pathogenesis_fence"
									:class="Reference.length == 0 ? 'Pathogenesis_fence_top' : 'Pathogenesis_fence_bot'"
									v-if="Reference.length == 1 || Reference.length == 0"
								>
									暂无数据
								</div> -->
                <!-- 分页 -->
                <div class="Pathogenesis_page" v-if="Reference.length != 0">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="Reference_total"
                    @current-change="Referencelistpage"
                    @prev-click="Referencelastpage"
                    @next-click="Referencetnextpage"
                    :page-size="Reference_per_page"
                  ></el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="自定义搜索" name="second">
            <div class="Search_list">
              <div class="Search">
                <div class="Search_flex">
                  <el-input
                    v-model="Search"
                    placeholder="请输入搜索的关键词"
                    clearable
                  ></el-input>
                  <div class="Submit" @click="InputDiagnosisInfo">快速提交</div>
                </div>
                <div class="Search_Tips Tips">
                  <div class="Search_Tips_name">提示语：</div>
                  <div class="Search_Tips_txt">
                    <div>输入方式一︰六经归属，或脏腑辨证，或八纲六邪名称</div>
                    <div>输入方式二︰直接输入方证名称</div>
                  </div>
                </div>
                <div
                  class="Search_Tips matrix"
                  :style="{
                    height: SearchInfo.length == 0 ? '56px' : '100%',
                  }"
                >
                  <div class="Search_Tips_name">加入方阵：</div>
                  <div class="matrix_choice">
                    <div
                      class="matrix_choice_flex"
                      v-for="(item, index) in SearchInfo"
                      :key="index"
                    >
                      <el-checkbox
                        v-model="item.choice"
                        @change="CancelDiagnosis(item, index)"
                      ></el-checkbox>
                      <div>{{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Search_lists">
                <div class="Search_list_title">搜索列表：(按匹配指数排列)</div>
                <div class="Search_item_scroll">
                  <div
                    class="Search_item"
                    v-for="(item, index) in PathogenesisSearchInfo"
                    :key="index"
                  >
                    <div class="Search_num">{{ item.percent }}</div>
                    <div class="Search_zz">
                      <div class="Search_choice">
                        <el-checkbox
                          v-model="item.choice"
                          @change="ChoiceDiagnosis(item, index)"
                        ></el-checkbox>
                        <div>{{ item.name }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">六经:</div>
                        <div class="Search_data_txt">{{ item.liujing }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">脏腑经络:</div>
                        <div class="Search_data_txt">{{ item.zangfu }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">八纲六邪:</div>
                        <div class="Search_data_txt">{{ item.bagang }}</div>
                      </div>
                      <div class="Search_data">
                        <div class="Search_data_name">治则:</div>
                        <div class="Search_data_txt">{{ item.zhize }}</div>
                      </div>
                    </div>
                    <div class="Search_bz">
                      <div class="zz">
                        <div class="name">主症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.zhuzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                      <div class="qz">
                        <div class="name">全症:</div>
                        <div>
                          <div
                            class="zt"
                            v-for="(items, indexs) in item.quanzheng_json"
                            :key="indexs"
                          >
                            <div
                              class="zt1"
                              v-for="(itemss, indexss) in items.value"
                              :key="indexss"
                              :class="
                                ChoiceColor(PathogenesisArr, 2, itemss, item)
                                  ? 'zt_active1'
                                  : ''
                              "
                            >
                              {{ itemss }}
                              <span
                                v-if="
                                  items.value.length - 1 != indexss &&
                                    items.value.length != 1
                                "
                                >/</span
                              >
                            </div>
                            ;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 栅栏 -->
                <div
                  class="Pathogenesis_fence"
                  :class="
                    PathogenesisSearchInfo.length == 0
                      ? 'Pathogenesis_fence_top1'
                      : 'Pathogenesis_fence_bot'
                  "
                  v-if="PathogenesisSearchInfo.length == 0"
                >
                  暂无数据
                </div>
                <!-- 分页 -->
                <div
                  class="Pathogenesis_page"
                  v-if="PathogenesisSearchInfo.length != 0"
                >
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="PathogenesisSearchInfo_total"
                    @current-change="Searchlistpage"
                    @prev-click="Searchlastpage"
                    @next-click="Searchnextpage"
                    :page-size="PathogenesisSearchInfo_per_page"
                  ></el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      MedicalInfo: [],
      DiagnosisArr: [],
      PathogenesisArr: [], //病机
      activeName: "first",
      PathogenesisInfo: [],
      PathogenesisSearchInfo: [],
      PathogenesisSearchInfo_page: 1,
      PathogenesisSearchInfo_total: null,
      PathogenesisSearchInfo_per_page: 0,
      SearchInfo: [],
      Search: "",
      SystemList: [], //系统推荐
      ChoiceShow: false,
      SystemList_page: 1,
      SystemList_total: null,
      SystemList_per_page: 0,
      Reference: [], //参考病机
      Reference_page: 1,
      Reference_total: null,
      Reference_per_page: 0,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    ChoiceColor() {
      return (info, type, item, arr) => {
        let data = info;
        let Show = false;
        if (data.length != 0) {
          // type 1 判断四诊信息
          // type 2 判断列表
          if (type == 1) {
            for (let x = 0; x < data.length; x++) {
              for (let y = 0; y < data[x].zhuzheng_json.length; y++) {
                for (
                  let z = 0;
                  z < data[x].zhuzheng_json[y].value.length;
                  z++
                ) {
                  let name = data[x].zhuzheng_json[y].value[z].split("（");
                  let name2 = item.name.split("（");
                  if (name[0] == name2[0]) {
                    Show = true;
                  }
                }
              }
              for (let y = 0; y < data[x].quanzheng_json.length; y++) {
                for (
                  let z = 0;
                  z < data[x].quanzheng_json[y].value.length;
                  z++
                ) {
                  let name = data[x].quanzheng_json[y].value[z].split("（");
                  let name2 = item.name.split("（");
                  if (name[0] == name2[0]) {
                    Show = true;
                  }
                }
              }
            }
          }
          if (type == 2) {
            let DiagnosisArr = this.DiagnosisArr;
            let Type = arr.choice;
            // 并且 选中状态
            for (let x = 0; x < DiagnosisArr.length; x++) {
              console.log(item, "12346");
              let name = DiagnosisArr[x].name.split("（");
              let name2 = item.split("（");
              if (name[0] == name2[0] && Type) {
                Show = true;
              }
            }
          }
        }
        return Show;
      };
    },
  },
  created() {
    if (!this.$route.params.id) return this.$router.go(-1);
    this.GetMedicalInfo();
    this.GetMedicalType();
    this.GetPathogenesis();
    this.GetSystem();
    this.GetReference();
    if (this.$route.params.type) {
      setTimeout(() => {
        this.FindPathogenesis();
      }, 500);
    }
    this.$nextTick(() => {
      setTimeout(() => {
        let msg = document.getElementsByClassName("Lndividual_from_scroll")[0];
        let scrollHei = document.getElementsByClassName("beizhu")[0];
        msg.scrollTop = scrollHei.scrollHeight;
      }, 200);
    });
  },
  methods: {
    handleClick(tab, event) {
      //   console.log(tab, event);
    },
    // 查看病机
    async FindPathogenesis() {
      let { data } = await this.$axios(
        "/api/yian/get_bingji?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      // 数据转换
      let PathogenesisArr = data.data.list;
      let Zzhuang = data.data.data;
      let arr = [];
      // 推荐列表
      let SystemList = this.SystemList;
      let Reference = this.Reference;
      for (let x = 0; x < PathogenesisArr.length; x++) {
        let obj = {};
        obj.name = PathogenesisArr[x].name;
        obj.percent = PathogenesisArr[x].percent + "%";
        obj.liujing = PathogenesisArr[x].liujing;
        obj.zangfu = PathogenesisArr[x].zangfu;
        obj.bagang = PathogenesisArr[x].bagang;
        obj.zhize = PathogenesisArr[x].zhize;
        //
        obj.zhuzheng_json = JSON.parse(Zzhuang[x].zhuzheng_json);
        for (let y = 0; y < obj.zhuzheng_json.length; y++) {
          obj.zhuzheng_json[y].value = obj.zhuzheng_json[y].value.split(",");
        }
        //
        // obj.quanzheng_json = JSON.parse(Zzhuang[x].quanzheng_json);
        obj.quanzheng_json = JSON.parse(Zzhuang[x].quanzheng_json);
        for (let y = 0; y < obj.quanzheng_json.length; y++) {
          obj.quanzheng_json[y].value = obj.quanzheng_json[y].value.split(",");
        }
        //
        obj.id = PathogenesisArr[x].pat_id;
        arr.push(obj);
        // 去查锐减列表
        // find
        for (let y = 0; y < SystemList.length; y++) {
          // console.log(SystemList[y].id);
          if (SystemList[y].id == PathogenesisArr[x].pat_id) {
            this.SystemList[y].choice = true;
          }
        }
        // 去查参考列表
        for (let y = 0; y < Reference.length; y++) {
          // console.log(Reference[y].id);
          if (Reference[y].id == PathogenesisArr[x].pat_id) {
            this.Reference[y].choice = true;
          }
        }
        // 去查搜索
      }
      this.PathogenesisArr = arr;
      console.log(this.PathogenesisArr);
    },
    // 上一步
    GetPrevious() {
      this.$router.push({
        name: "Lndividual",
        params: { id: this.$route.params.id },
      });
    },
    // 下一步
    async GetNext() {
      let PathogenesisArr = this.PathogenesisArr;
      if (PathogenesisArr.length == 0) return this.ElmMsg("请选择病机!");
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      console.log(PathogenesisArr);
      for (let x = 0; x < PathogenesisArr.length; x++) {
        // arr.push(PathogenesisArr[x].id);
        // let num = Number(PathogenesisArr[x].percent);
        let percent = PathogenesisArr[x].percent.slice(
          0,
          PathogenesisArr[x].percent.length - 1
        );
        // arr1.push(percent);
        arr2.push({
          id: PathogenesisArr[x].id,
          percent: percent,
        });
      }
      arr2.forEach((item, index) => {
        arr.push(item.id);
        arr1.push(item.percent);
      });
      arr = arr.join(",");
      arr1 = arr1.join(",");
      let { data } = await this.$axios.post(
        "/api/yian/set_bingji",
        {
          yian_id: this.$route.params.id,
          bingji_id: arr,
          percent: arr1,
        },
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.$router.push({
        name: "Group",
        params: { id: this.$route.params.id },
      });
    },
    // 重置
    Reset() {
      this.PathogenesisArr = [];
      this.activeName = "first";
      this.GetSystem();
      // this.GetReference();
      this.Search = "";
      this.SearchInfo = [];
      this.PathogenesisSearchInfo = [];
    },
    async GetMedicalInfo() {
      let { data } = await this.$axios.get(
        "/api/yian/get_basic?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      this.MedicalInfo = data.data;
    },
    async GetMedicalType() {
      let { data } = await this.$axios.get(
        "/api/yian/get_bingzheng?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 1) {
        let DiagnosisArr = data.data;
        let arr = [];
        for (let x = 0; x < DiagnosisArr.length; x++) {
          let obj = {};
          obj.name = DiagnosisArr[x];
          obj.choice = false;
          arr.push(obj);
        }
        this.DiagnosisArr = arr;
      }
    },
    // 推荐
    async GetPathogenesis() {
      let { data } = await this.$axios.get(
        "/api/yian/get_recommended?id=" + this.$route.params.id,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) return this.CleanLogin();
      if (data.code == 1) {
        this.PathogenesisInfo = data.data.data;
      }
    },
    // 选中 搜索出的方剂
    ChoiceDiagnosis(item, index) {
      console.log(item);
      if (item.choice) {
        this.PathogenesisSearchInfo.choice = true;
      } else {
        this.PathogenesisSearchInfo.choice = false;
      }
      let obj = {};
      obj.name = item.name;
      obj.choice = true;
      obj.index = index;
      let type = item.choice ? 1 : 2;
      this.CountDiagnosisInfo(obj, type);
      if (type == 1) {
        this.PushPathogenesis(item);
      } else {
        this.DeletePathogenesis(item);
      }
    },
    CountDiagnosisInfo(obj, type) {
      if (type == 1) {
        this.PushPrescription(obj);
      } else {
        this.DeletePrescription(obj);
      }
    },
    // 加入病机
    PushPathogenesis(obj) {
      let PathogenesisArr = this.PathogenesisArr;
      if (PathogenesisArr.length == 0) {
        return PathogenesisArr.push(obj);
      }
      for (let x = 0; x < PathogenesisArr.length; x++) {
        if (PathogenesisArr[x].name != obj.name) {
          return PathogenesisArr.push(obj);
        }
      }
      this.PathogenesisArr = PathogenesisArr;
    },
    // 取消病机
    DeletePathogenesis(obj) {
      let PathogenesisArr = this.PathogenesisArr;
      let Num;
      for (let x = 0; x < PathogenesisArr.length; x++) {
        if (PathogenesisArr[x].name == obj.name) {
          Num = x;
        }
      }
      PathogenesisArr.splice(Num, 1);
      this.PathogenesisArr = PathogenesisArr;
    },
    // 加入方证
    PushPrescription(obj) {
      let SearchInfo = this.SearchInfo;
      if (SearchInfo.length != 0) {
        for (let x = 0; x < SearchInfo.length; x++) {
          if (SearchInfo[x].name != obj.name) {
            return SearchInfo.push(obj);
          }
        }
      } else {
        SearchInfo.push(obj);
      }
      this.SearchInfo = SearchInfo;
    },
    // 取消方证
    CancelDiagnosis(item, index) {
      this.DeletePrescription(item, 2);
      this.DeletePathogenesis(item);
    },
    // 删除方证
    DeletePrescription(obj, type = 1) {
      let SearchInfo = this.SearchInfo;
      let Num;
      if (SearchInfo.length != 0) {
        for (let x = 0; x < SearchInfo.length; x++) {
          if (SearchInfo[x].name == obj.name) {
            Num = x;
          }
        }
      }
      SearchInfo.splice(Num, 1);
      this.SearchInfo = SearchInfo;
      if (type == 2) {
        this.PathogenesisSearchInfo[obj.index].choice = false;
      }
    },
    // 快速搜索
    async InputDiagnosisInfo() {
      if (!this.Search) {
        return this.ElmMsg("搜索条件不能为空", 1);
      }
      //   this.PathogenesisSearchInfo_page = 1;
      let { data } = await this.$axios.get(
        "/api/yian/get_search?id=" +
          this.$route.params.id +
          "&keyword=" +
          this.Search +
          "&page=" +
          this.PathogenesisSearchInfo_page,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      if (data.code == 1) {
        // this.ElmMsg(data.msg, 2);
        let PathogenesisInfo = data.data.data;
        if (PathogenesisInfo.length != 0) {
          let PathogenesisArr = this.PathogenesisArr;
          // 转json
          for (let x = 0; x < PathogenesisInfo.length; x++) {
            if (PathogenesisInfo[x].custom_json) {
              PathogenesisInfo[x].custom_json = JSON.parse(
                PathogenesisInfo[x].custom_json
              );
            }
            //
            if (PathogenesisInfo[x].fangji_json) {
              PathogenesisInfo[x].fangji_json = JSON.parse(
                PathogenesisInfo[x].fangji_json
              );
            }
            //
            if (PathogenesisInfo[x].jianfu_json) {
              PathogenesisInfo[x].jianfu_json = JSON.parse(
                PathogenesisInfo[x].jianfu_json
              );
            }
            //
            if (PathogenesisInfo[x].jinji_json) {
              PathogenesisInfo[x].jinji_json = JSON.parse(
                PathogenesisInfo[x].jinji_json
              );
            }
            //
            if (PathogenesisInfo[x].leifang_json) {
              PathogenesisInfo[x].leifang_json = JSON.parse(
                PathogenesisInfo[x].leifang_json
              );
            }
            // 全症
            if (PathogenesisInfo[x].quanzheng_json) {
              PathogenesisInfo[x].quanzheng_json = JSON.parse(
                PathogenesisInfo[x].quanzheng_json
              );
              for (
                let y = 0;
                y < PathogenesisInfo[x].quanzheng_json.length;
                y++
              ) {
                PathogenesisInfo[x].quanzheng_json[y].value = PathogenesisInfo[
                  x
                ].quanzheng_json[y].value.split(",");
              }
            }
            // 主症
            if (PathogenesisInfo[x].zhuzheng_json) {
              PathogenesisInfo[x].zhuzheng_json = JSON.parse(
                PathogenesisInfo[x].zhuzheng_json
              );
              for (
                let y = 0;
                y < PathogenesisInfo[x].zhuzheng_json.length;
                y++
              ) {
                PathogenesisInfo[x].zhuzheng_json[y].value = PathogenesisInfo[
                  x
                ].zhuzheng_json[y].value.split(",");
              }
            }
            //

            if (PathogenesisArr.length != 0) {
              if (
                PathogenesisArr.findIndex(
                  (item) => item.id == PathogenesisInfo[x].id
                ) > -1
              )
                PathogenesisInfo[x].choice = true;
              else PathogenesisInfo[x].choice = false;
              // for (let h = 0; h < PathogenesisArr.length; h++) {
              // 	if (PathogenesisArr[h].id == PathogenesisInfo[x].id) {
              // 		PathogenesisInfo[x].choice = true;
              // 	} else {
              // 		PathogenesisInfo[x].choice = false;
              // 	}
              // }
            }
          }
          this.PathogenesisArr = PathogenesisArr;
        }
        // console.log(PathogenesisInfo);

        this.PathogenesisSearchInfo = PathogenesisInfo;
        console.log(this.PathogenesisSearchInfo);
        // 分页
        this.PathogenesisSearchInfo_total = data.data.total;
        this.PathogenesisSearchInfo_page = data.data.current_page;
        this.PathogenesisSearchInfo_per_page = data.data.per_page;
      }
    },
    // 系统推荐
    async GetSystem() {
      let { data } = await this.$axios.get(
        "/api/yian/get_recommended?id=" +
          this.$route.params.id +
          "&page=" +
          this.SystemList_page,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let PathogenesisInfo = data.data.data;
      if (PathogenesisInfo.length != 0) {
        // 转json
        let PathogenesisArr = this.PathogenesisArr;
        for (let x = 0; x < PathogenesisInfo.length; x++) {
          if (PathogenesisInfo[x].custom_json) {
            PathogenesisInfo[x].custom_json = JSON.parse(
              PathogenesisInfo[x].custom_json
            );
          }
          //
          if (PathogenesisInfo[x].fangji_json) {
            PathogenesisInfo[x].fangji_json = JSON.parse(
              PathogenesisInfo[x].fangji_json
            );
          }
          //
          if (PathogenesisInfo[x].jianfu_json) {
            PathogenesisInfo[x].jianfu_json = JSON.parse(
              PathogenesisInfo[x].jianfu_json
            );
          }
          //
          if (PathogenesisInfo[x].jinji_json) {
            PathogenesisInfo[x].jinji_json = JSON.parse(
              PathogenesisInfo[x].jinji_json
            );
          }
          //
          if (PathogenesisInfo[x].leifang_json) {
            PathogenesisInfo[x].leifang_json = JSON.parse(
              PathogenesisInfo[x].leifang_json
            );
          }
          // 全症
          if (PathogenesisInfo[x].quanzheng_json) {
            PathogenesisInfo[x].quanzheng_json = JSON.parse(
              PathogenesisInfo[x].quanzheng_json
            );
            for (
              let y = 0;
              y < PathogenesisInfo[x].quanzheng_json.length;
              y++
            ) {
              PathogenesisInfo[x].quanzheng_json[y].value = PathogenesisInfo[
                x
              ].quanzheng_json[y].value.split(",");
            }
          }
          // 主症
          if (PathogenesisInfo[x].leifang_json) {
            PathogenesisInfo[x].zhuzheng_json = JSON.parse(
              PathogenesisInfo[x].zhuzheng_json
            );
            for (let y = 0; y < PathogenesisInfo[x].zhuzheng_json.length; y++) {
              PathogenesisInfo[x].zhuzheng_json[y].value = PathogenesisInfo[
                x
              ].zhuzheng_json[y].value.split(",");
            }
          }
          // 判断情况
          PathogenesisInfo[x].choice = false;

          if (PathogenesisArr.length != 0) {
            if (
              PathogenesisArr.findIndex(
                (item) => item.id == PathogenesisInfo[x].id
              ) > -1
            )
              PathogenesisInfo[x].choice = true;
            else PathogenesisInfo[x].choice = false;
            // for (let h = 0; h < PathogenesisArr.length; h++) {
            // 	if (PathogenesisArr[h].id == PathogenesisInfo[x].id) {
            // 		PathogenesisInfo[x].choice = true;
            // 	} else {
            // 		PathogenesisInfo[x].choice = false;
            // 	}
            // }
          }
        }
        this.PathogenesisArr = PathogenesisArr;
      }
      // console.log(PathogenesisInfo);
      this.SystemList = PathogenesisInfo;
      // 分页
      this.SystemList_total = data.data.total;
      this.SystemList_page = data.data.current_page;
      this.SystemList_per_page = data.data.per_page;
    },
    // 参考病机
    async GetReference() {
      let { data } = await this.$axios.get(
        "/api/yian/get_reference?id=" +
          this.$route.params.id +
          "&page=" +
          this.Reference_page,
        {
          headers: {
            token: JSON.parse(localStorage.getItem("userinfo")).token,
          },
        }
      );
      if (data.code == 401) {
        return this.CleanLogin();
      }
      if (data.code != 1) {
        return this.ElmMsg(data.msg);
      }
      let PathogenesisInfo = data.data.data;
      if (PathogenesisInfo.length != 0) {
        let PathogenesisArr = this.PathogenesisArr;
        // 转json
        for (let x = 0; x < PathogenesisInfo.length; x++) {
          if (PathogenesisInfo[x].custom_json) {
            PathogenesisInfo[x].custom_json = JSON.parse(
              PathogenesisInfo[x].custom_json
            );
          }
          //
          if (PathogenesisInfo[x].fangji_json) {
            PathogenesisInfo[x].fangji_json = JSON.parse(
              PathogenesisInfo[x].fangji_json
            );
          }
          //
          if (PathogenesisInfo[x].jianfu_json) {
            PathogenesisInfo[x].jianfu_json = JSON.parse(
              PathogenesisInfo[x].jianfu_json
            );
          }
          //
          if (PathogenesisInfo[x].jinji_json) {
            PathogenesisInfo[x].jinji_json = JSON.parse(
              PathogenesisInfo[x].jinji_json
            );
          }
          //
          if (PathogenesisInfo[x].leifang_json) {
            PathogenesisInfo[x].leifang_json = JSON.parse(
              PathogenesisInfo[x].leifang_json
            );
          }
          // 全症
          if (PathogenesisInfo[x].quanzheng_json) {
            PathogenesisInfo[x].quanzheng_json = JSON.parse(
              PathogenesisInfo[x].quanzheng_json
            );
            for (
              let y = 0;
              y < PathogenesisInfo[x].quanzheng_json.length;
              y++
            ) {
              PathogenesisInfo[x].quanzheng_json[y].value = PathogenesisInfo[
                x
              ].quanzheng_json[y].value.split(",");
            }
          }
          // 主症
          if (PathogenesisInfo[x].zhuzheng_json) {
            PathogenesisInfo[x].zhuzheng_json = JSON.parse(
              PathogenesisInfo[x].zhuzheng_json
            );
            for (let y = 0; y < PathogenesisInfo[x].zhuzheng_json.length; y++) {
              PathogenesisInfo[x].zhuzheng_json[y].value = PathogenesisInfo[
                x
              ].zhuzheng_json[y].value.split(",");
            }
          }
          //

          if (PathogenesisArr.length != 0) {
            if (
              PathogenesisArr.findIndex(
                (item) => item.id == PathogenesisInfo[x].id
              ) > -1
            )
              PathogenesisInfo[x].choice = true;
            else PathogenesisInfo[x].choice = false;
            // for (let h = 0; h < PathogenesisArr.length; h++) {
            // 	if (PathogenesisArr[h].id == PathogenesisInfo[x].id) {
            // 		PathogenesisInfo[x].choice = true;
            // 	} else {
            // 		PathogenesisInfo[x].choice = false;
            // 	}
            // }
          }
        }
        this.PathogenesisArr = PathogenesisArr;
        console.log(PathogenesisInfo, "参考病机");
        this.Reference = PathogenesisInfo;
        console.log("this.Reference", this.Reference);
        // 分页
        this.Reference_total = data.data.total;
        this.Reference_page = data.data.current_page;
        this.Reference_per_page = data.data.per_page;
      }
    },
    // 分页切换 重新渲染
    // 推荐分页切换
    SystemListlistpage(e) {
      let page = e;
      this.SystemList_page = page;
      this.GetSystem();
    },
    SystemListlastpage(e) {
      let page = e;
      this.SystemList_page = page;
      this.GetSystem();
    },
    SystemListnextpage(e) {
      let page = e;
      this.SystemList_page = page;
      this.GetSystem();
    },
    // 参考 分页切换
    Referencelistpage(e) {
      let page = e;
      this.Reference_page = page;
      this.GetReference();
    },
    Referencelastpage(e) {
      let page = e;
      this.Reference_page = page;
      this.GetReference(e);
    },
    Referencetnextpage(e) {
      let page = e;
      this.Reference_page = page;
      this.GetReference();
    },
    // 搜索分页切换
    Searchlistpage(e) {
      let page = e;
      this.PathogenesisSearchInfo_page = page;
      this.InputDiagnosisInfo();
    },
    Searchlastpage(e) {
      let page = e;
      this.PathogenesisSearchInfo_page = page;
      this.InputDiagnosisInfo();
    },
    Searchnextpage(e) {
      let page = e;
      this.PathogenesisSearchInfo_page = page;
      this.InputDiagnosisInfo();
    },
  },
};
</script>
<style lang="less">
.zt_active {
  color: #fff !important;
  background: #f75444 !important;
}
.zt_active1 {
  color: red !important;
}
.Lndividual {
  width: 100%;
  .Lndividual_from {
    width: 100%;
    background: #ffffff;
    padding: 0 !important;
    box-sizing: border-box;
    // padding-bottom:0 ;
    // height: calc(100vh - 100px);
    .Lndividual_from_box {
      height: 35%;
      display: flex;
      justify-content: space-between;
      .Lndividual_from_box_left {
        width: 90%;
      }
      .Lndividual_from_scroll {
        height: calc(100% - 38px - 38px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .Medicals {
          flex: 1;
        }
        .DiagnosisInfo {
          padding-bottom: 10px;
        }
      }
    }
    .MedicalInfo {
      height: 38px;
    }
    .Medical {
      display: flex;
      border-right: 1px solid #dcdfe6;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #dcdfe6;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
        border: none;
      }
      .txt {
        // width: 150px;
        flex: 1;
      }
    }
    .MedicalRemarks {
      height: 66px;
      .input {
        width: calc(100% - 140px);
      }
    }
    .Diagnosis {
      // height: auto !important;
      border-right: 1px solid #dcdfe6;
      .DiagnosisInfo {
        width: calc(100% - 140px);
        overflow: auto;
        height: 100%;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        flex-wrap: wrap;
        // border-right: none !important;
        &::-webkit-scrollbar {
          display: none;
        }
        .DiagnosisItems {
          width: 100%;
          margin-top: 10px;
          .DiagnosisItems_title {
            width: 100%;
            display: flex;
            font-size: 14px;
            font-weight: 600;
          }
          .DiagnosisItems_info {
            width: 100%;
            display: flex;
            margin-top: 16px;
            .DiagnosisItems_info_flex {
              display: flex;
              .DiagnosisItems_info_flex_name {
                font-weight: 600;
              }
              .DiagnosisItems_info_flex_txt {
                margin-left: 4px;
                margin-right: 6px;
              }
            }
          }
        }
        .DiagnosisItem {
          padding: 5px 12px;
          box-sizing: border-box;
          //   background: #409eff;
          color: #ffffff;
          height: 30px;
          border-radius: 4px;
          margin-right: 8px;
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            background: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 2px;
            box-sizing: border-box;
            font-size: 12px;
          }
        }
      }
    }
    .Medicals {
      display: flex;
      .div {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
        letter-spacing: 2px;
        height: 100%;
      }
      .name {
        background: #f5f5f5;
        color: #333;
        width: 140px;
        font-weight: 600;
      }
      .txt {
        width: 150px;
      }
    }
    .Physician {
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-left: 1px solid #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
      border-right: 1px solid #dcdfe6;
      .PhysicianInfo {
        display: flex;
        height: 100%;
        div {
          border-bottom: none !important;
          border-top: none !important;
          border-right: none !important;
        }
      }
    }
    .operation {
      // width: 100% !important;
    }
    .operation_row {
      display: block;
      width: 10% !important;
      div {
        margin-top: 15px;
      }
    }
    .operation_top {
      margin-top: 30px;
    }
    .Lists {
      width: 100%;
      // margin-top: 30px;
      overflow: auto;
      height: calc(65%);
      .el-tabs {
        height: 100% !important;
      }
      .el-tabs__header {
        margin-bottom: 0 !important;
      }
      .el-tabs__content {
        height: calc(100% - 40px);
        .el-tab-pane,
        .tuijian_list,
        .Search_list {
          height: 100%;
        }
        .Search_item_scroll {
          height: calc(100% - 36px - 32px);
          overflow-y: auto;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .tuijian_list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .Search_lists {
          width: 100%;
          .Search_zz {
            width: 30%;
          }
          .Search_bz {
            width: 60%;
          }
        }
      }
      .Search_list {
        display: flex;
        justify-content: space-between;
        .Search {
          border-top: 1px solid #dcdfe6;
          width: 48%;
          height: 100%;
          .Search_flex {
            padding: 10px 20px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            border-left: 1px solid #dcdfe6;
            border-right: 1px solid #dcdfe6;
            .el-input {
              width: 340px;
            }
            .Submit {
              width: 100px;
              height: 38px;
              background: #409eff;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              cursor: pointer;
              margin-left: 10px;
              border-radius: 4px;
            }
          }
          .Tips {
            height: 50px;
          }
          .Search_Tips {
            display: flex;
            border-top: 1px solid #dcdfe6;
            border-left: 1px solid #dcdfe6;
            border-right: 1px solid #dcdfe6;
            .Search_Tips_name {
              width: 140px;
              display: flex;
              align-items: center;
              padding-left: 15px;
              box-sizing: border-box;
            }
            .Search_Tips_txt {
              display: flex;
              flex-direction: column;
              justify-content: center;
              border-left: 1px solid #dcdfe6;
              padding-left: 20px;
              box-sizing: border-box;
              div {
                margin-top: 6px;
                color: #999999;
              }
            }
          }
          .matrix {
            height: 100%;
            border-bottom: 1px solid #dcdfe6;
            .matrix_choice {
              display: flex;
              flex-direction: column;
              justify-content: center;
              border-left: 1px solid #dcdfe6;
              padding-left: 20px;
              box-sizing: border-box;
              .matrix_choice_flex {
                display: flex;
                padding: 20px 0;
                div {
                  margin-left: 6px;
                }
              }
            }
          }
        }
      }
    }
    .Search_lists {
      width: 48%;
      border: 1px solid #dcdfe6;
      .Search_list_title {
        height: 36px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        box-sizing: border-box;
        background: #f9f9f9;
        color: #999999;
      }
      .Pathogenesis_page {
        display: flex;
        justify-content: flex-end;
      }
      .Pathogenesis_fence_top {
        border-top: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
      }
      .Pathogenesis_fence_top1 {
        border-top: 1px solid #dcdfe6;
      }
      .Pathogenesis_fence_bot {
        border-bottom: 1px solid #dcdfe6;
      }
      .Pathogenesis_fence {
        height: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        font-size: 16px;
      }
      .Search_item {
        width: 100%;
        display: flex;
        border-top: 1px solid #dcdfe6;
        border-bottom: 1px solid #dcdfe6;
        height: auto;
        .Search_num {
          width: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .Search_zz {
          width: 40%;
          border-left: 1px solid #dcdfe6;
          padding: 8px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .Search_choice {
            display: flex;
            align-items: center;
            div {
              margin-left: 4px;
              font-weight: 600;
              font-size: 14px;
            }
          }
          .Search_data {
            display: flex;
            line-height: 2;
            .Search_data_name {
              font-weight: 600;
              margin-right: 5px;
            }
            .Search_data_txt {
              color: #999999;
            }
          }
        }
        .Search_bz {
          width: 50%;
          border-left: 1px solid #dcdfe6;
          padding: 8px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .qz {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-top: 1px dashed #999999;
            // flex-wrap: wrap;
            padding-top: 5px;
            .name {
              font-weight: 600;
              letter-spacing: 1px;
              font-size: 12px;
              display: flex;
              align-items: center;
              margin-right: 10px;
              width: 35px;
            }
            .zt {
              color: #999999;
              letter-spacing: 2px;
              display: flex;
              font-size: 12px;
              line-height: 1.5;
              flex-wrap: wrap;
              .zt1 {
                color: #999999;
                letter-spacing: 2px;
                font-size: 12px;
              }
            }
            > div:last-child {
              flex: 1;
            }
          }
          .zz {
            width: 100%;
            margin-top: 8px;
            display: flex;
            // flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 10px;
            .name {
              font-weight: 600;
              letter-spacing: 1px;
              font-size: 12px;
              display: flex;
              align-items: center;
              margin-right: 10px;
              width: 35px;
            }
            .zt {
              color: #999999;
              letter-spacing: 2px;
              display: flex;
              font-size: 12px;
              line-height: 1.5;
              flex-wrap: wrap;
              .zt1 {
                color: #999999;
                letter-spacing: 2px;
                font-size: 12px;
              }
            }
            > div:last-child {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
</style>

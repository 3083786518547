import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//  elm ui
import installElementPlus from "./plugins/element";
//
import axios from "axios";

const app = createApp(App);
installElementPlus(app);
//判断真假
let ifdev = process.env.NODE_ENV === "development";
// 项目打包
// http://admin.yiguanjiankang.com
const baseURL = ifdev
  ? "https://admin.yiguanjk.com/"
  : "https://admin.yiguanjk.com/";
axios.defaults.baseURL = baseURL;
// axios.defaults.withCredentials = true //让axios携带cookie
// 实例化
app.config.globalProperties.$axios = axios;
//全局守卫
router.beforeEach((to, from, next) => {
  let token = JSON.parse(localStorage.getItem("userinfo")) || [];
  // console.log(token)
  if (token.token || to.path === "/Login") {
    next();
  } else {
    next("/Login");
  }
});
// 全局挂载
import Eml from "./assets/js/common";
app.config.globalProperties.$Eml = Eml;

axios.interceptors.response.use(
  (res) => {
    // console.log(res)
    // if(res.code == 401){
    // 	this.CleanLogin()
    // }
    return res;
  },
  (err) => {
    if (err) {
      if (err.message.indexOf("401") > -1) {
        localStorage.removeItem("userinfo");
        router.push("/Login");
      }
      //在这里关闭请求时的loading动画效果
      // Toast.fail("请求网络失败")
    }
    return Promise.reject(err);
  }
);

//
import mixins from "./mixins/mixins";
app.mixin(mixins);
app
  .use(store)
  .use(router)
  .mixin({
    data() {
      return {
        baseURL,
      };
    },
  })
  .mount("#app");
